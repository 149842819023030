import Lottie from "react-lottie";
import React, { useEffect } from "react";
import styled from "styled-components";
import hello from "../../assets/lottie-files/robot-says-hello.json";
import InputField from "../../components/InputField";
import { useState } from "react";
import { Switch, withStyles } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { userLoginUrl } from "../../api/UserAPI";
import { useDispatch, useSelector } from "react-redux";
import { accessToken, loginSuccess } from "../../slices/user/userSlice";
import { companiesUrl, viewUserCompanyUrl } from "../../api/CompanyAPI";
import { companyDetailsSuccess } from "../../slices/companyDetails/companyDetailsSlice";
import swal from "sweetalert";

const SignIn = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.user);
  const [state, setState] = useState({
    username: "",
    password: "",
    isRemember: false,
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hello,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(async () => {
    if (isAuth) {
      navigate("dashboard/customers");
    }
  }, []);
  const AntSwitch = muiStyled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleSwitch = (e) => {
    const { name, checked } = e.target;
    setState({ [name]: checked });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = state;
    const loginResponse = await fetch(userLoginUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then((response) => response.json());

    if (loginResponse.success === 6000) {
      if (loginResponse.role === "superuser") {
        const companyResponse = await fetch(companiesUrl, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${loginResponse.access}`,
          },
        }).then((response) => response.json());
        dispatch(
          companyDetailsSuccess({
            ...companyResponse.data[0],
          })
        );

        dispatch(
          loginSuccess({
            ...loginResponse.data,
            username: loginResponse.username,
            isAuth: true,
          })
        );
      } else {
        const companyResponse = await fetch(viewUserCompanyUrl, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${loginResponse.access}`,
          },
          body: JSON.stringify({
            userid: loginResponse.user_id,
          }),
        }).then((response) => response.json());
        dispatch(
          companyDetailsSuccess({
            ...companyResponse.data,
          })
        );

        dispatch(
          loginSuccess({
            ...loginResponse.data,
            username: loginResponse.username,
            isAuth: true,
          })
        );
      }

      navigate("dashboard/customers");
    } else {
      swal({
        text: loginResponse.error,
        icon: "warning",
      });
    }
  };

  console.log(state);
  return (
    <Container>
      <SignInContainer>
        <LottieContainer>
          <LottieImage>
            <Lottie
              options={defaultOptions}
              isClickToPauseDisabled={true}
              style={{ cursor: "unset" }}
            />
          </LottieImage>
        </LottieContainer>
        <SignInputBox>
          <Heading>Sign In</Heading>
          <InputRow>
            <InputField
              label="Username"
              name="username"
              value={state.username}
              handleChange={handleChange}
            />
            <InputField
              label="Password"
              type="password"
              name="password"
              value={state.password}
              handleChange={handleChange}
            />
          </InputRow>
          {/* <ForgotPasswordButton>Forgotten Password?</ForgotPasswordButton> */}
          <BottomButton>
            {/* <RememberMeContainer>
              <SwitchLabel>Remember Me</SwitchLabel>
              <AntSwitch
                checked={state.isRemember}
                onChange={(e) => handleSwitch(e)}
                name="isRemember"
              />
            </RememberMeContainer> */}
            <SigninButton type="submit" onClick={(e) => handleSubmit(e)}>
              Sign in
            </SigninButton>
          </BottomButton>
        </SignInputBox>
      </SignInContainer>
    </Container>
  );
};

export default SignIn;

const Container = styled.div`
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(29, 94, 135, 1) 0%,
    rgba(34, 133, 117, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SignInContainer = styled.div`
  width: 750px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  padding: 25px;
`;
const SignInputBox = styled.div`
  border: 1px solid #ccc;
  width: 350px;
  border-radius: 8px;
  padding: 20px;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 30px;
  color: #1b5e7d;
  margin-bottom: 10px;
`;
const LottieImage = styled.div`
  transform: scale(0.8);
  width: 100%;
`;
const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1vw;
  margin-bottom: 20px;
`;
const ForgotPasswordButton = styled.div`
  color: #228276;
  cursor: pointer;
  font-size: 14px;
  text-align: right;
`;
const RememberMeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
const SwitchLabel = styled.p`
  margin-right: 10px;
  color: #4e4e4e;
  font-size: 14px;
`;
const BottomButton = styled.div`
  margin-top: 150px;
`;
const SigninButton = styled.button`
  border: 0;
  padding: 10px 25px;
  background: linear-gradient(
    135deg,
    rgba(29, 94, 135, 1) 0%,
    rgba(34, 133, 117, 1) 100%
  );
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  display: block;
  width: 100%;
`;
const LottieContainer = styled.div`
  width: 70%;
  display: flex;
`;
