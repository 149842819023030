import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { companiesUrl } from "../../../api/CompanyAPI";
import { userLogoutUrl } from "../../../api/UserAPI";
import {
  companyDetailsLogoutSuccess,
  companyDetailsSuccess,
} from "../../../slices/companyDetails/companyDetailsSlice";
import { accessToken, logoutSuccess } from "../../../slices/user/userSlice";
import CompanyList from "./CompanyList";

const Header = () => {
  const image_root = window.location.origin;
  const dispatch = useDispatch();
  const access_token = useSelector(accessToken);
  const { id, CompanyName, CompanyLogo } = useSelector(
    (state) => state.companyDetails
  );
  const { username, role } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [state, setState] = useState({
    companies: [],
    loading: true,
  });
  const [showComapanyList, setComapanyList] = useState(false);

  useEffect(async () => {
    const companyResponse = await fetch(companiesUrl, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    }).then((response) => response.json());

    console.log(companyResponse);
    if (companyResponse.StatusCode === 6000) {
      setState((prevState) => {
        return {
          ...prevState,
          companies: companyResponse.data,
          loading: false,
        };
      });
    }
  }, []);

  const switchCompany = async (id) => {
    let companies = state.companies;
    dispatch(
      companyDetailsSuccess({
        ...companies.filter((i) => i.id === id)[0],
      })
    );
    window.location.reload();
  };

  // const switchCompany = async () => {
  //   const companyResponse = await fetch(companiesUrl, {
  //     method: "GET",
  //     headers: {
  //       "content-type": "application/json",
  //       accept: "application/json",
  //       Authorization: `Bearer ${access_token}`,
  //     },
  //   }).then((response) => response.json());
  //   dispatch(
  //     companyDetailsSuccess({
  //       ...companyResponse.data.filter((i) => i.id !== id)[0],
  //     })
  //   );
  //   window.location.reload();
  // };

  const handleLogout = () => {
    fetch(userLogoutUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    dispatch(logoutSuccess());
    dispatch(companyDetailsLogoutSuccess());

    navigate("/");
  };

  return (
    <Container>
      <TopMenu>
        {role === "superuser" ? (
          <LeftMenu
            // onClick={switchCompany}
            onClick={() => setComapanyList(!showComapanyList)}
          >
            <CompanyImage src={CompanyLogo} />
            <CompanyNameText>{CompanyName}</CompanyNameText>
            <CompanyList
              state={state}
              switchCompany={switchCompany}
              showComapanyList={showComapanyList}
            />
          </LeftMenu>
        ) : (
          <LeftMenu>
            <CompanyImage src={CompanyLogo} />
            <CompanyNameText>{CompanyName}</CompanyNameText>
          </LeftMenu>
        )}
        <RightMenu>
          <UserName>{username}</UserName>
          <UserImage src={image_root + "/images/user.svg"} />
          {role === "superuser" ? (
            <SettingsIcon
              onClick={() => navigate("/dashboard/settings")}
              src={image_root + "/images/icons/settings.svg"}
            />
          ) : (
            <SettingsIcon
              onClick={handleLogout}
              src={image_root + "/images/icons/logout.svg"}
            />
          )}
        </RightMenu>
      </TopMenu>
    </Container>
  );
};

export default Header;
const Container = styled.div`
  background: #ececec;
`;
const TopMenu = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;
const LeftMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
`;

const RightMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 25px;
`;
const CompanyImage = styled.img`
  width: 30px;
  padding: 5px 0;
`;
const UserImage = styled.img`
  width: 25px;
  margin: 0 10px;
`;
const SettingsIcon = styled.img`
  width: 25px;
  cursor: pointer;
  margin-left: 10px;
`;
const CompanyNameText = styled.div`
  line-height: 30px;
  font-weight: bold;
  margin-left: 5px;
`;
const UserName = styled.div`
  line-height: 30px;
  margin-left: 10px;
`;
