import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { printInvoiceUrl } from "../api/SaleAPI";
import $ from "jquery";

const PrintTemplate = (props) => {
  const [state, setstate] = useState(0);

  // useEffect(async () => {
  //   setTimeout(() => {
  //     props.setPrint({ print: false, invoice_id: 0 });
  //   }, 1000);
  // });

  const invoice_type = "sales_invoice";
  return (
    <ModalContainer showPrint>
      <Container id="divcontents">
        <ButtonBox>
          <HeadingContainer>
            <Heading>Preview</Heading>
          </HeadingContainer>
          <ButtonContainer>
            {/* <Button onClick={(e) => print("myFrame")}>Print</Button> */}
            <Button
              className="preview"
              onClick={() => props.handlePrintCancel()}
            >
              Close
            </Button>
          </ButtonContainer>
        </ButtonBox>
        <FrameContainer>
          <Frame
            id="myFrame"
            name="myFrame"
            src={`${printInvoiceUrl}?invoice_id=${props.invoice_id}&invoice_type=${props.invoice_type}&print_template=${props.print_template}`}
            frameborder="0"
          ></Frame>
        </FrameContainer>
      </Container>
    </ModalContainer>
  );
};

export default PrintTemplate;

const Container = styled.div`
  position: relative;
  width: 1030px;
  max-width: 1030px;
  height: max-content;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
`;
const HeadingContainer = styled.div`
  background: whitesmoke;
`;
const Heading = styled.p`
  font-size: 25px;
`;
const ContentContainer = styled.div`
  padding: 10px;
`;
const ButtonContainer = styled.div``;
const RadioButton = styled.input``;
const TextContainer = styled.div`
  margin-left: 5px;
`;
const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
const Text = styled.p`
  color: #777777;
  font-size: 14px;
`;
const ButtonBox = styled.div`
  background: #f8f7f7;
  display: flex;
  position: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Button = styled.button`
  &.preview {
    background: #f5f5f5;
    border: 1px solid #ccc;
    color: #000;
    margin-left: 5px;
  }
  border: 0;
  cursor: pointer;
  background: green;
  color: #fff;
  padding: 8px 25px;
  border-radius: 3px;
`;
const Frame = styled.iframe`
  width: 100%;
  height: 80vh;
  border: 0;
`;
const FrameContainer = styled.div`
  margin: 15px;
`;

const ModalContainer = styled.div`
  background: rgb(0 0 0 / 50%);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 100;
  display: none;
  @media print {
    & {
      display: block;
      justify-content: unset;
    }
    ${ButtonBox} {
      display: none;
    }
    ${Container} {
      position: unset;
      width: 100%;
      max-width: unset;
      background: unset;
      border: unset;
      border-radius: unset;
    }
    ${Frame} {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: 0;
      width: 100%;
    }
    ${FrameContainer} {
      margin: 0;
    }
  }
`;
