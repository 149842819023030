import React, { useEffect, useState } from "react";
import "./ImageUpload.scss";
import $ from "jquery";
import "font-awesome/css/font-awesome.min.css";

const ImageUpload = (props) => {
  useEffect(() => {
    var readURL = function (input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $(".profile-pic").attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
      }
    };

    $(".file-upload").on("change", function () {
      readURL(this);
    });

    $(".upload-button").on("click", function () {
      $(".file-upload").click();
    });
  }, []);
  return (
    <div className="avatar-wrapper">
      <img className="profile-pic" src={props.file} />
      <div className="upload-button">
        <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
      </div>
      <input
        className="file-upload"
        onChange={props.onChange}
        type="file"
        name={props.name}
        accept="image/*"
      />
    </div>
  );
};

export default ImageUpload;
