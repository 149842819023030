import { useEffect } from "react";
// import moment from "moment";

export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}

function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        console.log(false);
        // alert("You clicked outside of me!");
      }
      return false;
    }
  }, [ref]);
}

export function stringCamelCase(string) {
  var split_string = string.split(" ");

  let final_arr = [];
  let final_name = "";

  for (let index = 0; index < split_string.length; index++) {
    const element = split_string[index];
    let name = element.charAt(0).toUpperCase() + element.slice(1);
    console.log(name);
    if (final_name) {
      final_name += " " + String(name);
    } else {
      final_name = String(name);
    }
    final_arr.push(final_name);
  }
  var lastItem = final_arr.pop();

  return lastItem;
}
// truncate number of words by passing the paragraph and number
export function truncate(str, num = 12) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}
// to check the list of values is present in the array
export const listChecker = (arr, target) =>
  target.every((v) => arr.includes(v));

export const getInitials = function (string) {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
// to get index value from a array by passing array by passing the list of values and number
export const get_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.id === id);
  }
  return index;
};
export const get_Ledger_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.LedgerID === id);
  }
  return index;
};

export const get_Product_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductID === id);
  }
  return index;
};

export const get_Unit_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PriceListID === id);
  }
  return index;
};

export const get_Tax_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TaxID === id);
  }
  return index;
};

export const get_warehouse_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.WarehouseID === id);
  }
  return index;
};

export const get_unit_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.UnitID === id);
  }
  return index;
};
export const get_group_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductGroupID === id);
  }
  return index;
};
export const get_category_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.ProductCategoryID === id);
  }
  return index;
};

export const get_priceCat_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.PriceCategoryID === id);
  }
  return index;
};

export const get_brand_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.BrandID === id);
  }
  return index;
};

export const get_tax_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TaxID === id);
  }
  return index;
};

export const get_route_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.RouteID === id);
  }
  return index;
};

export const get_value_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.value === id);
  }
  return index;
};

export const get_branch_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.BranchID === id);
  }
  return index;
};
// convert datetime object to python date string
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const findDuplicate = (array1, array2) => {
  var duplicates = array1.filter((val) => array2.indexOf(val) !== -1);
  return duplicates;
};

// just pass the id to show the password
export const showPassword = (id) => {
  var x = document.getElementById(id);
  console.log(x);
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
};

// download Excel
export function download_excel(url) {
  var element = window.document.createElement("a");
  element.href = url;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// date to string(today)
export function today_date() {
  var today = new Date();
  today.setDate(today.getDate());
  var Today = today.toISOString().substr(0, 10);
  return Today;
}
// date to string(today)
// export function date_range(type) {
//   var fromDate = "";
//   var toDate = "";

//   if (type == "This Month") {
//     var date = new Date(),
//       y = date.getFullYear(),
//       m = date.getMonth();
//     var firstDay = new Date(y, m, 1);
//     var lastDay = new Date(y, m + 1, 0);
//     var fromDate = moment(firstDay).format("YYYY-MM-DD");
//     var toDate = moment(lastDay).format("YYYY-MM-DD");
//   } else if (type == "Last Month") {
//     var date = new Date(),
//       y = date.getFullYear(),
//       m = date.getMonth();
//     var firstDay = new Date(y, m - 1, 1);
//     var lastDay = new Date(y, m, 0);
//     var fromDate = moment(firstDay).format("YYYY-MM-DD");
//     var toDate = moment(lastDay).format("YYYY-MM-DD");
//   } else if (type == "Today") {
//     var today = new Date();
//     today.setDate(today.getDate());
//     var Today = today.toISOString().substr(0, 10);
//     fromDate = Today;
//     toDate = Today;
//   } else if (type == "This Week") {
//     var curr = new Date(); // get current date
//     let week = [];

//     for (let i = 1; i <= 7; i++) {
//       let first = curr.getDate() - curr.getDay() + i;
//       let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
//       week.push(day);
//     }
//     fromDate = week[0];
//     toDate = week.slice(-1)[0];
//     console.log(fromDate);
//     console.log(toDate);
//   } else if (type == "Last 90 Days") {
//     var curr = new Date(); // get current date
//     var first = curr.getDate(); // First day is the day of the month - the day of the week
//     var last = first - 90; // last day is the first day + 6
//     var firstDay = new Date(curr.setDate(last));
//     var lastDay = new Date(curr.setDate(first));
//     var fromDate = moment(firstDay).format("YYYY-MM-DD");
//     var toDate = moment(lastDay).format("YYYY-MM-DD");
//   } else if (type == "This Year") {
//     var date = new Date(),
//       y = date.getFullYear(),
//       m = date.getMonth();
//     var firstDay = new Date(date.getFullYear(), 0, 1);
//     var lastDay = new Date(new Date().getFullYear(), 11, 31);
//     var fromDate = moment(firstDay).format("YYYY-MM-DD");
//     var toDate = moment(lastDay).format("YYYY-MM-DD");
//   } else if (type == "Last Year") {
//     var date = new Date(),
//       y = date.getFullYear(),
//       m = date.getMonth();
//     var firstDay = new Date(date.getFullYear() - 1, 0, 1);
//     var lastDay = new Date(new Date().getFullYear() - 1, 11, 31);
//     var fromDate = moment(firstDay).format("YYYY-MM-DD");
//     var toDate = moment(lastDay).format("YYYY-MM-DD");
//   }
//   console.log(fromDate, toDate);
//   return [fromDate, toDate];
// }
// to get age pass date of birth
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
// to get avatar images just pass a value you will get a random image
export const getAvatar = (value = "1", imageType = "identicon") => {
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);

  // Create an hash of the string
  const hash = hashCode(value);
  // Grab the actual image URL

  // return `https://avatars.dicebear.com/api/${imageType}/${hash}.svg`;
  return `https://www.gravatar.com/avatar/${hash}?s=46&d=${imageType}&r=PG&f=1`;
};

export const get_Transaction_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.TransactionTypesID === id);
  }
  return index;
};

// check image exists
export const imageExists = (image_url) => {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, false);
  http.send();

  return http.status != 404;
};

export const dataURLtoFile = async (dataurl) => {
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  console.log(response);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  return file;
};

export const getArraySum = (array, amount) => {
  return array.reduce(function (a, b) {
    return a + b[amount];
  }, 0);
};
