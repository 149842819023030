import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import swal from "sweetalert";
import { importQuotationUrl } from "../../api/QuotationAPI";
import { customersUrl } from "../../api/UserAPI";
import QuotationTable from "../../components/QuotationTable";
import Table from "../../components/Table";
import TopHeader from "../../components/TopHeader";
import TopHeaderCreate from "../../components/TopHeaderCreate";
import { get_index, today_date } from "../../functions/utils";
import { accessToken } from "../../slices/user/userSlice";

const ImportQuotation = (props) => {
  const companyDetails = useSelector((state) => state.companyDetails);
  const company_id = companyDetails.id;
  const access_token = useSelector(accessToken);

  const [state, setState] = useState({
    data: [],
    from_date: today_date(),
    to_date: today_date(),
  });
  useEffect(async () => {
    await fetchCustomerData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const fetchCustomerData = async () => {
    await fetch(`${customersUrl}/${company_id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      // body: JSON.stringify({
      //   CompanyID: CompanyID,
      // }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              customer_list: response.data,
            };
          });
        }
      });
  };

  const [showOptionList, setOptionList] = useState(0);
  const handleOption = (id) => {
    if (id === showOptionList) {
      setOptionList(0);
    } else {
      console.log(id);
      setOptionList(id);
    }
  };
  const editItem = () => {};
  const deleteItem = () => {};
  const handleSubmit = async () => {
    await fetch(importQuotationUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        customer_id: state.customer_id,
        status: state.status,
        from_date: state.from_date,
        to_date: state.to_date,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              data: response.data,
            };
          });
          if (response.data.length === 0) {
            swal({
              title: "Quotation not found for this customer",
              text: "",
              icon: "warning",
            });
          }
        }
      });
  };
  const handleAutoComplete = (e, v, n) => {
    if (v) {
      // if customer and value exists the value will be taken
      setState({ ...state, [n]: v.id });
    } else {
      // the loyalty customer details removed from the box
      setState({ ...state, [n]: "" });
    }
  };

  const tableHead = [
    "Sl No",
    "Voucher No",
    "Date",
    "VAT No",
    "Discount",
    "Total",
  ];
  const tableBody = [
    "quotation_number",
    "quotation_date",
    "customer_vat",
    "bill_discount_amt",
    "grand_total",
  ];
  console.log(state);
  return (
    <ModalContainer>
      {" "}
      <Container>
        <Header>Import From Quotation</Header>
        <InputRow>
          <InputGroup>
            <Label>Customer</Label>
            <Autocomplete
              id="customer_id"
              options={state.customer_list ? state.customer_list : []}
              getOptionLabel={(option) => option.CustomerName || ""}
              name="customer_id"
              onChange={(e, v) => handleAutoComplete(e, v, "customer_id")}
              value={
                state.customer_list
                  ? state.customer_list[
                      get_index(state.customer_list, state.customer_id)
                    ]
                  : ""
              }
              renderInput={(params) => (
                <StyleTextInput
                  {...params}
                  fullWidth
                  size="small"
                  id="customer_id"
                  name="customer_id"
                  value={state.customer_id}
                  variant="outlined"
                />
              )}
            />
          </InputGroup>

          <InputGroup>
            <Label>From</Label>
            <StyleTextInput
              className="top-input"
              fullWidth
              id="from_date"
              name="from_date"
              size="small"
              variant="outlined"
              type="date"
              value={state.from_date}
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
          <InputGroup>
            <Label>To</Label>
            <StyleTextInput
              className="top-input"
              fullWidth
              id="to_date"
              name="to_date"
              size="small"
              variant="outlined"
              type="date"
              value={state.to_date}
              onChange={(e) => handleChange(e)}
            />
          </InputGroup>
          <SearchButton type="button" onClick={handleSubmit}>
            Search
          </SearchButton>
        </InputRow>
        <Footer>
          <QuotationTable
            state={state}
            quotation_id={props.state.quotation_id}
            tableHead={tableHead}
            tableBody={tableBody}
            get_quotation={props.get_quotation}
            options={false}
          />
        </Footer>
        <BottomButtomContainer>
          <ActionButton onClick={() => props.setQuotation(false)}>
            Cancel
          </ActionButton>
          <ActionButton className="save" onClick={props.import_from_quotation}>
            Import
          </ActionButton>
        </BottomButtomContainer>
      </Container>
      ;
    </ModalContainer>
  );
};

export default ImportQuotation;

const ModalContainer = styled.div`
  background: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;
const Container = styled.div`
  position: relative;
  width: 750px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 30px 25px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
  background: #f6f6f6;
  border-radius: 3px;
`;
const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 1vw;
  margin-bottom: 20px;
  width: 100%;
`;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 2px !important;
  }
`;
const Label = styled.p`
  width: 100%;
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const StyleTextInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
    padding: 4.5px 14px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #959595;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
const BottomButtomContainer = styled.div`
  margin-top: 60px;
  text-align: right;
`;
const ActionButton = styled.button`
  cursor: pointer;
  padding: 10px 45px;
  background: #b51110;
  border: 0;
  border-radius: 3px;
  color: #fff;
  &.save {
    background: #229d00;
    margin-left: 10px;
  }
`;
const SearchButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 10px 25px;
  background: green;
  color: #fff;
  height: fit-content;
  margin-top: auto;
  border-radius: 3px;
`;
