import React from "react";
import styled from "styled-components";

const StatementTable = (props) => {
  return (
    <Container>
      <THead>
        <TableHeadRow>
          {props.tableHead.map((i, index) => (
            <TH key={index}>{i}</TH>
          ))}
        </TableHeadRow>
      </THead>
      <TBody>
        {[
          props.state.data.map((i, index) => (
            <TableBodyRow type={i.type}>
              {props.tableBody ? (
                props.page ? (
                  <TD>{(props.page - 1) * 20 + index + 1}</TD>
                ) : (
                  <TD>{index + 1}</TD>
                )
              ) : (
                []
              )}

              {props.tableBody
                ? props.tableBody.map((tb) => [
                    Object.keys(i).map((oneKey, j) => {
                      return (
                        tb === oneKey && (
                          <TD key={j}>
                            {oneKey === "payment_type" &&
                            props.status &&
                            i.type !== "credit_note" ? (
                              <Button
                                status={i[oneKey]}
                                onClick={() =>
                                  props.handleStatus(i.id, i.payment_type)
                                }
                              >
                                {i[oneKey] === "cash" ? "Paid" : "Unpaid"}
                              </Button>
                            ) : (i.type === "credit_note" &&
                                i[oneKey] === "credit") ||
                              i[oneKey] === "cash" ? null : (
                              i[oneKey]
                            )}
                          </TD>
                        )
                      );
                    }),
                  ])
                : []}
              {props.tableBody
                ? props.options && (
                    <TD>
                      <OptionMenu
                        onClick={() => props.handleOption(i.id)}
                        data-option-id={i.id}
                      >
                        <OptionImgContainer>
                          <OptionImg src="../images/icons/dot-menu.png" />
                        </OptionImgContainer>

                        {i.id === props.showOptionList
                          ? [
                              <OptionListContainer showOption={true}>
                                <OptionList
                                  onClick={(id) => props.editItem(i.id)}
                                >
                                  Edit
                                </OptionList>
                                <OptionList
                                  onClick={(id) => props.deleteItem(i.id)}
                                >
                                  Delete
                                </OptionList>
                                {props.print && (
                                  <OptionList
                                    onClick={(id) =>
                                      props.handlePrint({
                                        print: true,
                                        invoice_id: i.id,
                                      })
                                    }
                                  >
                                    Print
                                  </OptionList>
                                )}
                              </OptionListContainer>,
                            ]
                          : null}
                      </OptionMenu>
                    </TD>
                  )
                : []}
            </TableBodyRow>
          )),
          props.status && (
            <TableBodyRow
              className="color"
              credit_note_only={props.credit_note_only}
            >
              <TD></TD>
              <TD>Total</TD>
              <TD></TD>
              <TD></TD>
              <TD>{props.state.gross_amt_sum}</TD>
              <TD>{props.state.vat_amt_sum}</TD>
              <TD>{props.state.discount_sum}</TD>
              <TD>{props.state.grand_total_sum}</TD>
              <TD></TD>
            </TableBodyRow>
          ),
        ]}
      </TBody>
    </Container>
  );
};

export default StatementTable;

const Container = styled.table`
  border-radius: 15px;
  width: 100%;
  background: #fff;
  border-collapse: collapse;
`;
const THead = styled.thead`
  background: #1d5e87;
`;
const TH = styled.th`
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
  color: #fff;
`;
const TD = styled.td`
  padding: 0.5em;
  text-align: left;
  border-bottom: 1px solid #ababab;
  vertical-align: middle;
`;
const TBody = styled.tbody``;
const TableHeadRow = styled.tr``;
const TableBodyRow = styled.tr`
  &.color {
    background: #ccc;
    font-weight: bold;
  }
  ${({ type }) =>
    type === "credit_note" &&
    `
  color:#ff0000;
`}
  ${({ credit_note_only }) =>
    credit_note_only &&
    `
  color:#ff0000;
`}
`;

const OptionImgContainer = styled.div`
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-left: auto;
`;
const OptionImg = styled.img`
  width: 100%;
  height: 100%;
`;
const OptionMenu = styled.div`
  position: relative;
`;
const OptionListContainer = styled.div`
  opacity: ${({ showOption }) => (showOption ? "1" : "0")};
  /* transform: ${({ showOption }) =>
    showOption ? "translateY(0)" : "translateY(-200%)"}; */

  /* visibility: ${({ showOption }) => (showOption ? "unset" : "hidden")}; */
  transition: all ease-in-out 0.3s;
  background: #fff;
  position: absolute;
  width: 150px;
  border-radius: 3px;
  top: 0;
  right: 25px;
  box-shadow: 0px 0px 3px 0px #ccc;
  z-index: 10;
`;
const OptionList = styled.div`
  display: block;
  padding: 10px;
  margin: 5px;
  background: #fff;
  transition: background ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    background: hsl(0deg 0% 92%);
    transition: background ease-in-out 0.3s;
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: 0;
  padding: 10px 25px;
  background: green;
  color: #fff;
  height: fit-content;
  margin-top: auto;
  border-radius: 3px;
  text-transform: capitalize;
  font-weight: bold;
  width: 90px;
  background: ${({ status }) => (status === "credit" ? "red" : "green")};
`;
