import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import MenuNav from "./MenuNav";

const SideMenu = () => {
  const { pathname } = useLocation();
  const image_root = window.location.origin;
  const Menu = [
    {
      label: "Customer",
      image: image_root + "/images/icons/customer.svg",
      link: "/dashboard/customers",
    },
    {
      label: "Sales",
      image: image_root + "/images/icons/salesinvoice.svg",
      link: "/dashboard/sales",
      isActive: ["/dashboard/sales", "/dashboard/create-sales"],
    },
    {
      label: "Quotation",
      image: image_root + "/images/icons/quotation.svg",
      link: "/dashboard/quotations",
    },
    {
      label: "Delivery Note",
      image: image_root + "/images/icons/deliverynote.svg",
      link: "/dashboard/delivery-notes",
    },
    {
      label: "Credit Note",
      image: image_root + "/images/icons/creditnote.svg",
      link: "/dashboard/credit-notes",
    },
    {
      label: "Debit Note",
      image: image_root + "/images/icons/debitnote.svg",
      link: "/dashboard/debit-notes",
    },
    {
      label: "Statements",
      image: image_root + "/images/icons/statements.svg",
      link: "/dashboard/statements",
    },
  ];
  return (
    <Container>
      <MenuContainer>
        {Menu.map((i, index) => (
          <MenuNav key={index} {...i} />
        ))}
      </MenuContainer>
    </Container>
  );
};

export default SideMenu;

const Container = styled.div`
  width: 100%;
`;
const MenuContainer = styled.div`
  width: 90%;
  padding: 5%;
`;
