import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../../components/InputField";
import ImageUpload from "../../../components/ImageUpload";

const UserBox = (props) => {
  //   let index_sundry = get_index(
  //     props.state.sundry_debters,
  //     props.state.AccountLedgerID
  //   );
  //   let index_cardtype = get_index(props.state.cardtypes, props.state.CardTypeID);
  //   let index_cardstatus = get_index(
  //     props.state.cardstatus,
  //     props.state.CardStatusID
  //   );
  console.log(props.showEdit);
  return (
    <ModalContainer>
      <Container>
        <CloseButton type="button" onClick={() => props.clearUser()}>
          <CloseIcon style={{ fontSize: "15px" }} />
        </CloseButton>

        <Form>
          <InputRow>
            <InputField
              label="Username"
              name="username"
              value={props.state.username}
              handleChange={props.handleChange}
            />
            <ImageUpload
              onChange={props.fileChange}
              file={props.state.photo}
              name="photo"
            />
          </InputRow>
          <InputRow>
            <InputField
              label="phone"
              name="phone"
              value={props.state.phone}
              handleChange={props.handleChange}
            />
      
          </InputRow>
          <InputRow>
            <InputField
              label="First Name"
              name="firstname"
              value={props.state.firstname}
              handleChange={props.handleChange}
            />
            <InputField
              label="Last Name"
              name="lastname"
              value={props.state.lastname}
              handleChange={props.handleChange}
            />
          </InputRow>
          <InputRow>
            <InputField
              label="Password"
              name="password"
              type="password"
              value={props.state.password}
              handleChange={props.handleChange}
            />
            <InputField
              label="Confirm Password"
              name="confirm_password"
              type="password"
              value={props.state.confirm_password}
              handleChange={props.handleChange}
            />
          </InputRow>

          <ButtonContainer>
            {props.showEdit ? (
              props.state.submitLoading ? (
                <Button type="button">Please wait...</Button>
              ) : (
                <Button
                  type="button"
                  onClick={(e) => props.handleEditUser(e, props.showEditId)}
                >
                  Edit User
                </Button>
              )
            ) : props.state.submitLoading ? (
              <Button type="button">Please wait...</Button>
            ) : (
              <Button type="button" onClick={(e) => props.handleCreateUser(e)}>
                Save
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </Container>
    </ModalContainer>
  );
};

export default UserBox;

const ModalContainer = styled.div`
  background: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;
const Container = styled.div`
  position: relative;
  width: 500px;
  height: 450px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 50px 26px 30px;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw;
  margin-bottom: 20px;
  align-items: center;
`;

const Form = styled.form``;
const Button = styled.button`
  background: #21971b;
  color: #fff;
  padding: 10px 50px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: ease 0.3s;
  &:hover {
    opacity: 0.8;
    transition: ease 0.3s;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;
const CloseButton = styled.button`
  border: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  border-radius: 10px;
  line-height: 7px;
  background: red;
  color: #fff;
  font-weight: bold;
  transition: ease 0.3s;
  &:hover {
    opacity: 0.5;
    transition: ease 0.3s;
  }
`;
const Label = styled.p`
  width: 100%;
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const StyleTextInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #959595;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 1px !important;
  }
`;
