import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const MenuNav = ({ image, label, link, isActive }) => {
  const { pathname } = useLocation();
  return (
    <Menu to={link} isActive={() => isActive.includes(pathname)}>
      <MenuIcon src={image} />
      <MenuText>{label}</MenuText>
    </Menu>
  );
};

export default MenuNav;
const Menu = styled(NavLink)`
  display: flex;
  width: 100%;
  width: 90%;
  padding: 10px 5%;
  border-radius: 20px;
  transition: all 0.3s ease;
  &:hover,
  &.active {
    background: #155064;
    transition: all 0.3s ease;
  }
`;
const MenuIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const MenuText = styled.p`
  margin-left: 15px;
  color: #fff;
  text-transform: capitalize;
`;
