import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import pageNotFound from "../../assets/lottie-files/page-not-found.json";

const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pageNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Container>
      <LottieImage>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          style={{ cursor: "unset" }}
        />
      </LottieImage>
    </Container>
  );
};

export default PageNotFound;

const Container = styled.div`
  color: #000;
  width: 100vh;
  height: 100%;
  margin: 0 auto;
  transform: scale(0.7);
  &::-webkit-scrollbar {
    display: none;
  }
`;
const LottieImage = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
`;
