import React from "react";
import styled from "styled-components/macro";
import { Route, Routes as Switch } from "react-router-dom";
import SideMenu from "./components/SideMenu";
import Header from "./components/Header";
import Dashboard from "../dashboard/Dashboard";
import ListCustomer from "../customer/ListCustomer";
import ListQuotation from "../quotation/ListQuotation";
import ListDeliveryNote from "../deliveryNotes/ListDeliveryNote";
import ListDebitNote from "../debitNotes/ListDebitNote";
import ListCreditNote from "../creditNotes/ListCreditNote";
import Statements from "../statements/Statements";
import ListSales from "../sales/ListSales";
import CreateSale from "../sales/CreateSale";
import CreateQuotation from "../quotation/CreateQuotation";
import CreateCreditNote from "../creditNotes/CreateCreditNote";
import CreateDebitNote from "../debitNotes/CreateDebitNote";
import CreateDeliveryNote from "../deliveryNotes/CreateDeliveryNote";
import Settings from "../settings/Settings";
import PageNotFound from "../home/PageNotFound";
import { useSelector } from "react-redux";

const Routes = () => {
  const { isAuth } = useSelector((state) => state.user);
  if (isAuth) {
    return (
      <Container>
        <MenuContainer>
          <SideMenu />
        </MenuContainer>
        <ViewPort>
          <Header />
          <SwitchContainer>
            <Switch>
              <Route path="/" element={<Dashboard />} />
              <Route path="/customers" element={<ListCustomer />} />
              <Route path="/sales" element={<ListSales />} />
              <Route path="/create-sales" element={<CreateSale />} />
              <Route path="/edit-sales/:id" element={<CreateSale />} />
              <Route path="/quotations" element={<ListQuotation />} />
              <Route path="/create-quotation" element={<CreateQuotation />} />
              <Route path="/edit-quotation/:id" element={<CreateQuotation />} />
              <Route path="/delivery-notes" element={<ListDeliveryNote />} />
              <Route
                path="/create-delivery-note"
                element={<CreateDeliveryNote />}
              />
              <Route
                path="/edit-delivery-note/:id"
                element={<CreateDeliveryNote />}
              />
              <Route path="/debit-notes" element={<ListDebitNote />} />
              <Route path="/create-debit-note" element={<CreateDebitNote />} />
              <Route
                path="/edit-debit-note/:id"
                element={<CreateDebitNote />}
              />
              <Route path="/credit-notes" element={<ListCreditNote />} />
              <Route
                path="/create-credit-note"
                element={<CreateCreditNote />}
              />
              <Route
                path="/edit-credit-note/:id"
                element={<CreateCreditNote />}
              />
              <Route path="/statements" element={<Statements />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="*" element={<PageNotFound />} />
            </Switch>
          </SwitchContainer>
        </ViewPort>
      </Container>
    );
  } else {
    return (
      <Switch>
        <Route path="*" element={<PageNotFound />} />
      </Switch>
    );
  }
};

export default Routes;

const Container = styled.section`
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 6fr;
`;
const MenuContainer = styled.div`
  background: linear-gradient(
    180deg,
    rgba(29, 94, 135, 1) 0%,
    rgba(34, 133, 117, 1) 100%
  );
  display: flex;
  align-items: center;
`;
const ViewPort = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;
const SwitchContainer = styled.div`
  padding: 15px;
`;
