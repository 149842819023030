import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import Table from "../../components/Table";
import TopHeader from "../../components/TopHeader";
import { Link, useNavigate } from "react-router-dom";
import { deleteSaleUrl, viewSaleUrl } from "../../api/SaleAPI";
import { useSelector } from "react-redux";
import { accessToken } from "../../slices/user/userSlice";
import PrintTemplate from "../../components/PrintTemplate";
import swal from "sweetalert";
import { Pagination } from "@mui/material";

const ListCreditNote = () => {
  const companyDetails = useSelector((state) => state.companyDetails);
  const access_token = useSelector(accessToken);
  const [showPrint, setPrint] = useState({ print: false, invoice_id: 0 });
  const handlePrint = ({ print, invoice_id }) => {
    setState({ ...state, random: state.random + 1 });
    setPrint({ print, invoice_id });
  };
  const company_id = companyDetails.id;
  const print_template = companyDetails.print_template;
  const navigate = useNavigate();
  let [page, setPage] = useState(1);
  const [state, setState] = useState({
    data: [],
    total: 0,
    random: 0,
  });
  const [showOptionList, setOptionList] = useState(0);
  const handleOption = (id) => {
    if (id === showOptionList) {
      setOptionList(0);
    } else {
      console.log(id);
      setOptionList(id);
    }
  };
  const editItem = (id) => {
    navigate("/dashboard/edit-credit-note/" + id);
  };
  const deleteItem = (id) => {
    swal({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${deleteSaleUrl + id}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`,
            // "accept": "application/json"
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.StatusCode === 6000) {
              swal({
                title: "Success",
                text: response.message,
                icon: "success",
              });
              fetchData();
            } else {
              swal({
                title: "failed",
                text: response.message,
                icon: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal("Credit Notes not deleted");
      }
    });
  };

  useEffect(async () => {
    fetchData();
  }, []);

  useEffect(async () => {
    await fetchData();
  }, [page]);
  const fetchData = async () => {
    await fetch(`${viewSaleUrl}?page=${page}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        company_id: company_id,
        type: "credit_note",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({
            ...state,
            data: response.data,
            total: Math.ceil(response.total / 20),
            loading: false,
          });
        }
      });
  };

  const tableHead = [
    "Sl No",
    "Customer Name",
    "Voucher Number",
    "Sales Date",
    "Payment Type",
    "Grand Total",
    "",
  ];
  const tableBody = [
    "customer_name",
    "voucher_number",
    "sales_date",
    "payment_type",
    "grand_total",
  ];
  return (
    <Container>
      <TopHeader
        title="Credit Notes"
        buttonTitle="New Credit Note"
        onClick={() => navigate("/dashboard/create-credit-note")}
      />
      <Footer>
        {state.data.length ? (
          <Table
            page={page}
            state={state}
            tableHead={tableHead}
            tableBody={tableBody}
            options={true}
            showOptionList={showOptionList}
            handleOption={handleOption}
            deleteItem={deleteItem}
            print={true}
            handlePrint={handlePrint}
            editItem={editItem}
          />
        ) : (
          <NotFount>no data found</NotFount>
        )}
      </Footer>
      {showPrint.print && (
        <PrintTemplate
          key={state.random}
          company_id={company_id}
          invoice_id={showPrint.invoice_id}
          showPrint={showPrint.print}
          setPrint={setPrint}
          invoice_type="credit_note"
          print_template={print_template}
        />
      )}
      {state.data.length > 0 && (
        <PaginationContainer>
          <StyledPagination
            count={state.total}
            // page={page}
            // size="small"
            onChange={(e, p) => setPage(p)}
          />
        </PaginationContainer>
      )}
    </Container>
  );
};

export default ListCreditNote;

const Container = styled.div`
  padding: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 15px;
`;
const NotFount = styled.div`
  text-align: center;
  margin-top: 100px;
  text-transform: capitalize;
  font-weight: bold;
`;
const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const StyledPagination = styled(Pagination)`
  width: 100%;
  .MuiPagination-ul {
    justify-content: center;
  }
`;
