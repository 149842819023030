import * as base from "../settings";

export const voucherNumberUrl = base.BASE_URL + "sales/voucher_number";
export const createSaleUrl = base.BASE_URL + "sales/create";
export const editSaleUrl = base.BASE_URL + "sales/edit-sale/";

export const viewSaleUrl = base.BASE_URL + "sales/view";
export const SaleUrl = base.BASE_URL + "sales/sale/";
export const printInvoiceUrl = base.BASE_URL + "sales/print-invoice";
export const deleteSaleUrl = base.BASE_URL + "sales/delete_sale/";
