import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

const FormsetInput = (props) => {
  return (
    <StyledInput
      disabled={props.disabled}
      fullWidth
      id={props.name}
      name={props.name}
      size="small"
      variant="outlined"
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e, props.index)}
      InputProps={props.InputProps}
    />
  );
};

export default FormsetInput;

const StyledInput = styled(TextField)`
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    background: #f6f6f6;
    box-shadow: 0 0 0 30px #f6f6f6 inset !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  /*Change text in autofill textbox*/
  /* input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #fff;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    /* border: 2px solid #e4e4e4; */
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #fff;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #898989;
    opacity: 1;
    font-size: 12px;
    letter-spacing: 1px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding: 0;
  }
`;
