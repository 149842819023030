import TextField from "@mui/material/TextField";
import React from "react";
import styled from "styled-components/macro";

const InputField = (props) => {
  return (
    <Container>
      <InputGroup>
        <Label>{props.label}:</Label>

        <StyledInput
          fullWidth
          id={props.name}
          name={props.name}
          size="small"
          variant="outlined"
          type={props.type}
          value={props.value}
          required
          onChange={(e) => props.handleChange(e)}
        />
      </InputGroup>
    </Container>
  );
};

export default InputField;

const Container = styled.div``;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 60%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const Label = styled.p`
  width: 100%;
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const StyledInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
    padding: 4px 14px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #959595;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
  &.MuiOutlinedInput-input {
    padding: 3.5px 14px;
  }
`;
