import React from "react";
import styled from "styled-components";

const UserList = (props) => {
  return (
    <Container>
      <UserDetails>
        {props.data.ProfilePic ? (
          <UserImage
            src={props.data.ProfilePic}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../images/user.svg";
            }}
          />
        ) : (
          <UserImage src="../images/user.svg" />
        )}
        <UserNameText>
          {props.data.first_name + " " + props.data.last_name}
        </UserNameText>
      </UserDetails>
      <EditButton
        onClick={(e) => props.handleCreateUserButton(e, props.data.user)}
      >
        <Image src="../images/icons/edit.svg" />
      </EditButton>
    </Container>
  );
};

export default UserList;

const Container = styled.div`
  background: #e8e8e8;

  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const UserDetails = styled.div`
  display: flex;
  align-items: center;
`;
const UserImage = styled.img`
  width: 45px;
`;
const UserNameText = styled.p`
  margin-left: 10px;
`;
const EditButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;
const Image = styled.img`
  width: 25px;
`;
