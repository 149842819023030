import React, { useState } from "react";
import styled from "styled-components/macro";
import DoneIcon from "@mui/icons-material/Done";

const SaveButton = (props) => {
  const [toggle, setToggle] = useState(false);
  return (
    <Container>
      <CreateButtonContainer
        border={props.border}
        color={props.background}
        type={props.type}
        onClick={props.onClick}
      >
        <CreateLabel color={props.color}>{props.title}</CreateLabel>
      </CreateButtonContainer>
      {props.saveButtonOption && (
        <MoreButtonContainer>
          <MoreButton
            type="button"
            onClick={() => setToggle(!toggle)}
          ></MoreButton>
          <OptionContainer toggle={toggle}>
            {props.saveButtonOption.map((i) => (
              <Options onClick={i.onClick}>
                <Icon>
                  <Image src={i.icon} />
                </Icon>
                <Text>{i.name}</Text>
              </Options>
            ))}
          </OptionContainer>
        </MoreButtonContainer>
      )}
    </Container>
  );
};

export default SaveButton;

const Container = styled.div`
  width: 100px;
  position: relative;
`;

const CreateLabel = styled.div`
  text-transform: capitalize;
  color: ${({ color }) => (color ? color : "#fff")};
  margin-left: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  transition: all ease-in-out 0.3s;
`;
const PlusIconPositionContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 25px;
  background: ${({ color }) => (color ? color : "#24a22b")};
  border-radius: 3px;
  padding: 5px;
  position: absolute;
  transition: width ease-in-out 0.3s;
`;
const CreateButtonContainer = styled.button`
  outline: none;
  border: 0;
  cursor: pointer;
  background: #fff;
  border-radius: 2px;
  padding: 15px 3px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  border: ${({ border }) => (border ? "1px solid " + border : "#229d00")};
  background: ${({ color }) => (color ? color : "#229d00")};

  /* &:hover,
  &:focus {
    ${CreateLabel} {
      color: #fff;
      transition: all ease-in-out 0.3s;
    }
    ${PlusIconPositionContainer} {
      width: 94%;
      transition: width ease-in-out 0.3s;
    }
  } */
`;

const PlusIconContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 25px;
  border-radius: 30px;
  padding: 7px;
  position: absolute;
  top: -6px;
  left: -6px;
  right: 0;
  bottom: 0;
  font-size: 23px;
  color: #fff;
  font-weight: lighter;
`;
const MoreButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  height: 100%;
  z-index: 10;
`;
const MoreButton = styled.button`
  border: 0;
  background: #229d00;
  height: 100%;
  width: 20px;
  margin: 3px;
  border-radius: 1px;
  height: -webkit-fill-available;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(315deg);
    top: 6px;
    left: 6px;
  }
`;
const OptionContainer = styled.div`
  visibility: visible;
  padding: 6px;
  width: max-content;
  height: max-content;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1px;
  position: absolute;
  right: -6px;
  top: 35px;
  transition: all 0.3s ease;
  ${({ toggle }) =>
    !toggle &&
    `
    visibility: hidden;
    opacity:0;
    transition: all 0.3s ease;
  `}
`;
const Options = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  margin-bottom: 3px;
  cursor: pointer;
  transition: ease 0.3s;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border-radius: 1px;
    background: #ccc;
    transition: ease 0.3s;
  }
`;
const Icon = styled.div`
  margin-right: 10px;
  width: 15px;
`;
const Text = styled.p`
  line-height: 0;
  font-size: 12px;
`;
const Image = styled.img`
  width: 100%;
`;
