import React, { useState } from "react";
import styled from "styled-components/macro";
import { TextField, Popper, Autocomplete } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { get_index } from "../../functions/utils";
import FormsetInput from "../../components/FormsetInput.js";

// product select autocomplete list custom width
const PopperMy = function (props) {
  return (
    <StyledPopper {...props} style={{ width: 1000 }} placement="bottom-start" />
  );
};

const SaleDetails = (props) => {
  const [showMoreDetails, setMoreDetails] = useState([]);
  return (
    <Container>
      <TableContainer onKeyPress={(e) => props.enterKeyChange(e)}>
        <Table>
          <THead>
            <TableHeadRow>
              <TH className="serial">Sl No</TH>
              <TH className="product">Product Name</TH>
              <TH className="quantity">Unit</TH>
              <TH className="quantity">Rate</TH>
              <TH className="quantity">Qty</TH>
              <TH className="quantity">VAT</TH>
              <TH className="quantity">Total Amount</TH>

              <TH className="action"></TH>
            </TableHeadRow>
          </THead>
          <TBody>
            {props.state.SaleDetails.map((i, index) => [
              <TableBodyRow>
                <TD style={{ verticalAlign: "middle", textAlign: "center" }}>
                  {index + 1}
                </TD>

                <TD>
                  <Autocomplete
                    disableClearable
                    freeSolo
                    id="product"
                    options={
                      props.state.productList ? props.state.productList : []
                    }
                    getOptionLabel={(option) => option.product_name || ""}
                    name="product_id"
                    onInputChange={(e, v) =>
                      props.handleInputChangeDetails(
                        e,
                        v,
                        "product_name",
                        index
                      )
                    }
                    onChange={(e, v) =>
                      props.handleAutoCompleteDetails(e, v, "product_id", index)
                    }
                    value={
                      props.state.productList &&
                      i.product_id &&
                      props.state.productList[
                        get_index(props.state.productList, i.product_id)
                      ]
                        ? props.state.productList[
                            get_index(props.state.productList, i.product_id)
                          ]
                        : ""
                    }
                    PopperComponent={PopperMy}
                    renderInput={(params) => (
                      <StyledInput
                        {...params}
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Product"
                        name="product_name"
                      />
                    )}
                  />
                  <FormsetInput
                    name="description"
                    value={i.description}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="Description"
                  />
                </TD>
                <TD>
                  <Autocomplete
                    freeSolo
                    id="unit_id"
                    options={props.state.unit_list ? props.state.unit_list : []}
                    getOptionLabel={(option) => option.unit_name || ""}
                    name="unit_id"
                    onChange={(e, v) =>
                      props.handleAutoCompleteDetails(e, v, "unit_id", index)
                    }
                    onInputChange={(e, v) =>
                      props.handleInputChangeDetails(e, v, "unit_name", index)
                    }
                    value={
                      props.state.unit_list && i.unit_id
                        ? props.state.unit_list[
                            get_index(props.state.unit_list, i.unit_id)
                          ]
                        : ""
                    }
                    renderInput={(params) => (
                      <StyledInput
                        {...params}
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Unit"
                      />
                    )}
                  />
                </TD>
                <TD>
                  <FormsetInput
                    name="rate"
                    value={i.rate}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="Rate"
                  />
                </TD>
                <TD>
                  <FormsetInput
                    name="quantity"
                    value={i.quantity}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="Qty"
                  />
                </TD>

                <TD>
                  <FormsetInput
                    name="vat_percentage"
                    value={i.vat_percentage}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="VAT %"
                    InputProps={{
                      endAdornment: "%",
                    }}
                  />
                  <FormsetInput
                    disabled
                    name="vat_amount"
                    value={i.vat_amount}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="SR XXXX"
                  />
                </TD>

                <TD>
                  <FormsetInput
                    disabled
                    name="net_total"
                    value={i.net_total}
                    index={index}
                    onChange={props.handleChangeDetails}
                    placeholder="SR XXXX"
                  />
                </TD>

                <TD style={{ textAlign: "center" }}>
                  <RemoveButton
                    type="button"
                    onClick={() => props.removeSaleDetails(i, index)}
                  >
                    <DeleteIcon />
                  </RemoveButton>
                </TD>
              </TableBodyRow>,
            ])}
          </TBody>
        </Table>
        <AddContainer>
          <AddButton type="button" onClick={() => props.addSaleDetails()}>
            + Add a line
          </AddButton>
        </AddContainer>
      </TableContainer>
    </Container>
  );
};

export default SaleDetails;

const Container = styled.div`
  margin-top: 5px;
  background: #fff;
  padding: 5px;
`;
const TableContainer = styled.form`
  overflow-x: scroll;
`;
const Table = styled.table`
  border-radius: 15px;
  width: 100%;
  background: #fff;
  border-collapse: separate;
  border-spacing: 2px 2px;
`;
const THead = styled.thead`
  box-shadow: 0 0 0 1px #c6c6c6;
  /* border-radius: 5px 5px 5px 5px; */
`;
const TH = styled.th`
  &.product-code {
    max-width: 140px;
    width: 140px;
    min-width: 140px;
  }
  &.serial {
    max-width: 55px;
    width: 55px;
    min-width: 55px;
  }
  &.product {
    max-width: 300px;
    width: 300px;
    min-width: 300px;
  }
  &.quantity {
    max-width: 60px;
    width: 60px;
    min-width: 60px;
  }
  &.gross-amount {
    max-width: 120px;
    width: 120px;
    min-width: 120px;
  }
  &.unit {
    max-width: 100px;
    width: 100px;
    min-width: 100px;
  }

  &.action {
    max-width: 28px;
    width: 28px;
    min-width: 28px;
  }
  padding: 1em;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 5px;
  white-space: nowrap;
  font-size: 12px;
`;
const TD = styled.td`
  padding: 3px;
  text-align: left;
  padding: 3px;
  /* border-bottom: 1px solid #ababab; */
  vertical-align: middle;
`;
const TBody = styled.tbody``;
const TableHeadRow = styled.tr``;
const TableBodyRow = styled.tr`
  background: #f6f6f6;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: 0;
  }
`;
const TableBodyRowMore = styled.tr`
  border-bottom: 1px solid #ccc;
  background: ${({ color }) => (color ? color : "#f6f6f6")};
  &:last-child {
    border: 0;
  }
`;
const ItemLabel = styled.p`
  font-size: 12px;
  margin-right: 10px;
  text-transform: capitalize;
`;
const Label = styled.p`
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
  text-transform: capitalize;
  width: 30%;
`;
const StyledInput = styled(TextField)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  /*Change text in autofill textbox*/
  /* input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #fff;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    /* border: 2px solid #e4e4e4; */
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #fff;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #898989;
    opacity: 1;
    font-size: 12px;
    letter-spacing: 1px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding: 0;
  }
`;
const BarcodeInput = styled(TextField)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  /*Change text in autofill textbox*/
  /* input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #fff;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 2px solid #e4e4e4;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #fff;
  }
  &.MuiFormControl-fullWidth {
    width: 70%;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
    font-family: "fml-sruthyregular";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-right: 35px;
  }
`;
const StyledSmallInput = styled(TextField)`
  background: #fff;
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  /*Change text in autofill textbox*/
  /* input:-webkit-autofill {
    -webkit-text-fill-color: yellow !important;
  } */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 120px;
    max-height: 120px;
    color: #fff;
    font-weight: bold;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 2px solid #e4e4e4;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #fff;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
    font-family: "fml-sruthyregular";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-right: 35px;
  }
`;
const RemoveButton = styled.button`
  cursor: pointer;
  background: #fff;
  border: 0;
  padding: 2px;
  transition: ease-in-out 0.3s;
  border-radius: 2px;
  background: ${({ color }) => (color ? color : "inherit")};
  color: ${({ color }) => (color ? "#fff" : "#000")};
  &:hover {
    background: #ccc;
    border-radius: 2px;
    transition: ease-in-out 0.3s;
  }
`;
const AddContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;
const AddButton = styled.button`
  cursor: pointer;
  background: #fff;
  border: 0;
  color: #3852bb;
  padding: 5px;
`;
const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemMainContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const BarcodeInputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 31%;
`;
const StyledPopper = styled(Popper)`
  &.MuiAutocomplete-popper {
    width: max-content !important;
    min-width: 377px;
    left: 160px;
    overflow-x: scroll;
  }
  & .MuiAutocomplete-listbox {
    padding: 0;
  }
  & .MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid #d0d0d0;
  }
  /* & .MuiAutocomplete-option {
    background-color: rgb(23 0 0 / 19%);
  } */
  /* & .MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgb(23 0 0 / 19%);
  } */

  /* & .MuiAutocomplete-option:hover {
    background-color: rgb(23 0 0 / 19%);
  } */
`;
