import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import AmountTextLabel from "../../components/AmountTextLabel";
import QuotationDetails from "./QuotationDetails";
import SettingsIcon from "@mui/icons-material/Settings";
import TopInputSelect from "../../components/TopInputSelect";
import TopHeaderCreate from "../../components/TopHeaderCreate";
import { createCustomerUrl, customersUrl } from "../../api/UserAPI";
import { useSelector } from "react-redux";
import { accessToken } from "../../slices/user/userSlice";
import {
  createSaleUrl,
  printInvoiceUrl,
  voucherNumberUrl,
} from "../../api/SaleAPI";
import $ from "jquery";
import { today_date } from "../../functions/utils";
import { unitsUrl } from "../../api/UnitsAPI";
import {
  getProductByIdUrl,
  productListUrl,
  translateUrl,
} from "../../api/ProductAPI";
import swal from "sweetalert";
import PrintTemplate from "../../components/PrintTemplate";
import NumberFormat from "react-number-format";
import {
  createQuotationUrl,
  editQuotationUrl,
  quotationNumberUrl,
  QuotationUrl,
} from "../../api/QuotationAPI";
import { useNavigate, useParams } from "react-router-dom";
var _ = require("lodash");

const CreateQuotation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const companyDetails = useSelector((state) => state.companyDetails);
  const company_id = companyDetails.id;
  const print_template = companyDetails.print_template;
  const [isQuotation, setQuotation] = useState(false);
  const access_token = useSelector(accessToken);
  const [showPrint, setPrint] = useState({ print: false, invoice_id: 0 });
  const handlePrintCancel = () => {
    setPrint({ print: false, invoice_id: 0 });
  };
  const [state, setState] = useState({
    data: [{ unit: 1 }, { unit: 2 }],
    QuotationDetails: [
      {
        id: "",
        product: "",
        description: "",
        rate: 0,
        quantity: 0,
        vat_percentage: 15,
        vat_amount: "",
        net_total: "",
        gross_amount: 0,
      },
    ],
    customer_id: "",
    terms_and_conditions: "",
    quotation_number: "",
    order_no: "",
    quotation_date: today_date(),
    valid_till: today_date(),
    bill_discount_percentage: 0,
    bill_discount_amt: 0.0,
    gross_amt: 0.0,
    vat_amt: 0.0,
    net_total: 0.0,
    grand_total: 0.0,
    payment_type: "credit",
    customer_list: [],
    unit_list: [],

    productList: [],
    product: "",
    loading: true,
  });
  useEffect(async () => {
    await fetchCustomerData();
    await fetchQuotationNumber();
    await fetchUnitList();
    await fetchProductList();
    await fetchData();
    // await fetchPrint();
  }, []);
  useEffect(async () => {
    let gross_amt = getGrossAmount(state.QuotationDetails);
    let vat_amt = getTotalVat(state.QuotationDetails);
    let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
    let grand_total = net_total;
    setState((prevState) => {
      return {
        ...prevState,
        gross_amt,
        net_total,
        grand_total,
      };
    });
    const bill_discount_amt =
      (gross_amt * state.bill_discount_percentage) / 100;
    CalculateBillDiscount(state.bill_discount_percentage, bill_discount_amt);
  }, [state.QuotationDetails]);
  // toggle customer search box
  const [addCustomer, setCustomer] = useState(false);
  // toggle loyalty customer search box
  const [addLoyaltyCustomer, setLoyaltyCustomer] = useState(false);
  // toggle shipping address modal
  const [addShippingAddress, setShippingAddress] = useState(false);
  // toggle loyalty customer modal
  const [loyaltyCustomerModal, setLoyaltyCustomerModal] = useState(false);

  // clearing the fields from loyalty customer modal
  const clearLoyaltyCustomer = () => {
    setState({
      ...state,
      name: "",
      pincode: "",
      building: "",
      area: "",
      city: "",
      district: "",
      state: "",
      country: "",
      phone: "",
    });
    setLoyaltyCustomerModal(false);
  };
  // set shipping address by clicking slider and remove shiping adddres by close button by giving empty string
  const onClickShippingAddress = (e, id) => {
    if (id) {
      // filtering from shipping addres list and select according to the selected id
      let shipping_address = state.shipping_address_list.filter(
        (i) => i.id == id
      );
      // closing shipping address modal
      setShippingAddress(false);
      setState({ ...state, shipping_address: shipping_address });
    } else {
      // remove shipping address
      setState({ ...state, shipping_address: "" });
    }
  };
  // getting the value from autocomplete input
  const handleAutoComplete = (e, v, n) => {
    if (n === "customer") {
      if (v) {
        // if customer and value exists the value will be taken
        setState({ ...state, [n]: v.id });
      } else {
        // the loyalty customer details removed from the box
        setState({ ...state, [n]: "" });
        setCustomer(false);
      }
    } else if (n === "loyalty_customer") {
      if (v) {
        // if customer and value exists the value will be taken
        setState({ ...state, [n]: v.id });
      } else {
        // the loyalty customer details removed from the box
        setState({ ...state, [n]: "" });
        setLoyaltyCustomer(false);
      }
    } else {
      // this condition applies for other autocomplete
      if (v) {
        // value will be taken
        setState({ ...state, [n]: v.id });
      } else {
        // if don't have a value the state will be empty string
        setState({ ...state, [n]: "" });
      }
    }
  };
  // remover unit form formset
  const removeQuotationDetails = (currentDetail, index) => {
    if (state.QuotationDetails.length > 1) {
      setState({
        ...state,
        QuotationDetails: state.QuotationDetails.filter(
          (id, idx) => idx !== index
        ),
      });
    }
  };
  // add unit in formset
  const addQuotationDetails = (e) => {
    setState({
      ...state,
      QuotationDetails: [
        ...state.QuotationDetails,
        {
          product: "",
          description: "",
          rate: 0,
          quantity: 0,
          vat_percentage: 15,
          vat_amount: "",
          net_total: "",
        },
      ],
    });
  };
  // getting serial number and item code from formset
  const handleSerialItemCode = (e, index) => {
    let QuotationDetails = [...state.QuotationDetails];
    QuotationDetails[index][e.target.name] = e.target.value;
    setState({
      ...state,
      QuotationDetails: QuotationDetails,
    });
  };
  // adding serial number and item code field when the plus button is clicked
  const addSerialItemCode = (index) => {
    let QuotationDetails = [...state.QuotationDetails];
    if (
      QuotationDetails[index]["SerialNo"] ||
      QuotationDetails[index]["ItemCode"]
    ) {
      QuotationDetails[index]["SerialNos"] = [
        ...QuotationDetails[index]["SerialNos"],
        {
          SerialNo: QuotationDetails[index]["SerialNo"],
          ItemCode: QuotationDetails[index]["ItemCode"],
        },
      ];

      setState({
        ...state,
        QuotationDetails: [...QuotationDetails],
      });
    }
  };
  // remove the serial number and item code field when the close button is clicked
  const removeSerialItemCode = (Index, SerialIndex) => {
    console.log(Index);
    setState({
      ...state,
      QuotationDetails: [
        {
          SerialNos: [
            ...state.QuotationDetails[Index].SerialNos.filter(
              (id, idx) => idx !== SerialIndex
            ),
          ],
        },
      ],
    });
  };
  // getting values from input
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value) {
      if (name === "bill_discount_percentage") {
        if (value <= 100) {
          const bill_discount_amt = (state.gross_amt * value) / 100;
          CalculateBillDiscount(value, bill_discount_amt);
        }
      } else if (name === "bill_discount_amt") {
        if (value <= state.gross_amt) {
          const bill_discount_percentage =
            (parseFloat(value) / parseFloat(state.gross_amt)) * 100;
          CalculateBillDiscount(bill_discount_percentage, value);
        }
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            [name]: value,
          };
        });
      }
    } else {
      if (name === "bill_discount_amt") {
        setState((prevState) => {
          return {
            ...prevState,
            [name]: 0,
          };
        });
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            [name]: "",
          };
        });
      }
    }
  };
  const handleInputChangeDetails = async (e, v, n, index) => {
    console.log(e);
    let QuotationDetails = [...state.QuotationDetails];
    if (v) {
      if (n === "product_name") {
        QuotationDetails[index][n] = v;
        if (e) {
          QuotationDetails[index]["product_id"] = "";
        }
        // value will be taken

        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
          };
        });
        await fetchProductList(v);
        if (!QuotationDetails[index]["product_id"]) {
          fetchDescription(v, index);
        }
      } else {
        QuotationDetails[index][n] = v;
        // value will be taken
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
          };
        });
      }
    } else {
      QuotationDetails[index][n] = "";
      // if don't have a value the state will be empty string
      setState((prevState) => {
        return {
          ...prevState,
          QuotationDetails,
        };
      });
    }
  };

  const handleAutoCompleteDetails = async (e, v, n, index) => {
    console.log("Event:", e);
    console.log("Value:", v);
    console.log("Name:", n);
    console.log("Index:", index);
    // this condition applies for other autocomplete
    let QuotationDetails = [...state.QuotationDetails];
    if (v) {
      if (n === "product_id") {
        QuotationDetails[index][n] = v.id;
        QuotationDetails[index]["rate"] = v.rate;
        QuotationDetails[index]["unit_id"] = v.unit;
        QuotationDetails[index]["description"] = v.description;
        QuotationDetails[index]["quantity"] = 1;

        // -------------
        QuotationDetails[index]["vat_amount"] = getVatAmount(
          QuotationDetails[index]["rate"],
          QuotationDetails[index]["quantity"],
          QuotationDetails[index]["vat_percentage"]
        );
        QuotationDetails[index]["net_total"] = getNetTotal(
          QuotationDetails[index]["quantity"],
          QuotationDetails[index]["rate"],
          QuotationDetails[index]["vat_amount"]
        );
        QuotationDetails[index]["gross_amount"] =
          QuotationDetails[index]["quantity"] * QuotationDetails[index]["rate"];

        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
        // -------------
      } else {
        QuotationDetails[index][n] = v.id;
      }
      // value will be taken
      setState((prevState) => {
        return {
          ...prevState,
          QuotationDetails,
        };
      });
    } else {
      // if don't have a value the state will be empty string
      QuotationDetails[index][n] = "";
      setState((prevState) => {
        return {
          ...prevState,
          QuotationDetails,
        };
      });
    }
  };

  const handleCheckbox = (e) => {
    setState({ ...state, payment_type: e.target.name });
  };

  const fetchData = async () => {
    if (id) {
      let quotationResponse = await fetch(`${QuotationUrl}${id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          // "accept": "application/json"
        },
        // body: JSON.stringify({
        //   CompanyID: CompanyID,
        // }),
      }).then((response) => response.json());

      var productIds = _.map(
        quotationResponse.data.quotation_items,
        "product_id"
      );

      let getProductByIdResponse = await fetch(`${getProductByIdUrl}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${access_token}`,
          // "accept": "application/json"
        },
        body: JSON.stringify({
          company_id: company_id,
          product_ids: productIds,
        }),
      }).then((response) => response.json());

      console.log(getProductByIdResponse);

      if (quotationResponse.StatusCode === 6000) {
        let bill_discount_percentage = 0
        let bill_discount_amt =
          parseFloat(quotationResponse.data.bill_discount_amt);
        let gross_amt = quotationResponse.data.gross_amt;
        if (bill_discount_amt != 0) {
          bill_discount_percentage =
            (parseFloat(bill_discount_amt) / parseFloat(gross_amt)) * 100;
          CalculateBillDiscount(bill_discount_percentage, bill_discount_amt);
        }
          
        setState((state) => {
          return {
            ...state,
            ...quotationResponse.data,
            customer_id: quotationResponse.data.customer,
            QuotationDetails: quotationResponse.data.quotation_items,
            productList: getProductByIdResponse.data,
          };
        });
      }
    }
    
  };

  const fetchCustomerData = async () => {
    await fetch(`${customersUrl}/${company_id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      // body: JSON.stringify({
      //   CompanyID: CompanyID,
      // }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              customer_list: response.data,
            };
          });
        }
      });
  };
  const fetchQuotationNumber = async () => {
    await fetch(`${quotationNumberUrl}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        company_id: company_id,
        type: "quotation_invoice",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              quotation_number: response.quotation_number,
            };
          });
        }
      });
  };
  const fetchDescription = async (keyword, index) => {
    let QuotationDetails = [...state.QuotationDetails];

    await fetch(`${translateUrl}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        language: "ar",
        keyword,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          QuotationDetails[index]["description"] = response.data;
          setState((state) => {
            return {
              ...state,
              QuotationDetails,
            };
          });
        }
      });
  };
  const fetchUnitList = async () => {
    await fetch(`${unitsUrl + company_id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              unit_list: response.data,
            };
          });
        }
      });
  };
  const fetchPrint = async () => {
    await fetch(printInvoiceUrl, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
        }
      });
  };

  const fetchProductList = async (search = "a") => {
    // await fetch(`${productListUrl + company_id}?q=${"a"}`, {
    await fetch(`${productListUrl + company_id}?q=${search}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          let result = _.unionBy(response.data, state.productList, "id");
          setState((state) => {
            return {
              ...state,
              productList: result,
              loading: false,
            };
          });
        }
      });
  };

  const handleChangeDetails = async (e, index) => {
    let { name, value } = e.target;

    let QuotationDetails = [...state.QuotationDetails];
    QuotationDetails[index][name] = value;

    if (name === "quantity") {
      if (value > 0 && QuotationDetails[index]["rate"] > 0) {
        if (!QuotationDetails[index]["vat_percentage"]) {
          QuotationDetails[index]["vat_percentage"] = 0;
        }
        QuotationDetails[index]["vat_amount"] = getVatAmount(
          QuotationDetails[index]["rate"],
          value,
          QuotationDetails[index]["vat_percentage"]
        );
        QuotationDetails[index]["net_total"] = getNetTotal(
          value,
          QuotationDetails[index]["rate"],
          QuotationDetails[index]["vat_amount"]
        );
        QuotationDetails[index]["gross_amount"] =
          value * QuotationDetails[index]["rate"];

        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      } else {
        QuotationDetails[index]["vat_amount"] = 0;
        QuotationDetails[index]["net_total"] = "";
        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      }
    } else if (name === "rate") {
      if (QuotationDetails[index]["quantity"] > 0 && value > 0) {
        if (!QuotationDetails[index]["vat_percentage"]) {
          QuotationDetails[index]["vat_percentage"] = 0;
        }
        QuotationDetails[index]["vat_amount"] = getVatAmount(
          QuotationDetails[index]["quantity"],
          value,
          QuotationDetails[index]["vat_percentage"]
        );
        QuotationDetails[index]["net_total"] = getNetTotal(
          QuotationDetails[index]["quantity"],
          value,
          QuotationDetails[index]["vat_amount"]
        );
        QuotationDetails[index]["gross_amount"] =
          value * QuotationDetails[index]["quantity"];
        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      } else {
        QuotationDetails[index]["vat_amount"] = 0;
        QuotationDetails[index]["net_total"] = "";
        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      }
    } else if (name === "vat_percentage") {
      if (
        QuotationDetails[index]["quantity"] > 0 &&
        QuotationDetails[index]["rate"] > 0
      ) {
        if (!value) {
          value = 0;
        }
        QuotationDetails[index]["vat_amount"] = getVatAmount(
          QuotationDetails[index]["quantity"],
          QuotationDetails[index]["rate"],
          value
        );
        QuotationDetails[index]["net_total"] = getNetTotal(
          QuotationDetails[index]["quantity"],
          QuotationDetails[index]["rate"],
          QuotationDetails[index]["vat_amount"]
        );
        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      } else {
        QuotationDetails[index]["vat_amount"] = 0;
        QuotationDetails[index]["net_total"] = "";
        let vat_amt = getTotalVat(QuotationDetails);
        let gross_amt = getGrossAmount(QuotationDetails);
        let net_total = parseFloat(gross_amt) + parseFloat(vat_amt);
        let grand_total = net_total;
        setState((prevState) => {
          return {
            ...prevState,
            QuotationDetails,
            vat_amt,
            gross_amt,
            net_total,
            grand_total,
          };
        });
      }
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          QuotationDetails,
        };
      });
    }
  };
  const getTotalVat = (items) => {
    let vat_amt = 0;
    items.map((i) => {
      if (i.rate && i.quantity) {
        vat_amt += parseFloat(i.vat_amount);
      }
    });
    return vat_amt;
  };
  const getVatAmount = (qty, rate, percentage) => {
    return (parseFloat(qty) * parseFloat(rate) * parseFloat(percentage)) / 100;
  };
  const getNetTotal = (qty, rate, tax) => {
    let total = parseFloat(qty) * parseFloat(rate);
    let netTotal = total + tax;
    return netTotal;
  };
  const getGrossAmount = (items) => {
    let grossAmount = 0.0;
    items.map((i) => {
      if (i.quantity > 0 && i.rate > 0)
        grossAmount += parseFloat(i.quantity) * parseFloat(i.rate);
    });
    return grossAmount;
  };
  // Calculate Bill Discount Function Starts Here
  const CalculateBillDiscount = (
    DiscountPercentage = 0,
    DiscountAmount = 0
  ) => {
    // Code here

    // Declaring variables
    var TaxableAmount = 0;
    var NonTaxableAmount = 0;

    // Chcking if obtaining correctly
    // console.log("Obtained Percentage: " + DiscountPercentage);
    // console.log("Obtained Amount: " + DiscountAmount);

    // Looping through the items
    state.QuotationDetails.map((i) => {
      // loop code here

      let ItemTaxAmount = parseFloat(i.vat_amount);

      let ItemGrossAmount = parseFloat(i.rate) * parseFloat(i.quantity);

      // Chcking if has tax or not
      if (ItemTaxAmount > 0) {
        TaxableAmount += ItemGrossAmount;
      } else {
        NonTaxableAmount += ItemGrossAmount;
      }
    });

    // Final Taxable and Non-Taxable Amount
    // console.log("Taxable Amount: " + TaxableAmount);
    // console.log("Non Taxable Amount: " + NonTaxableAmount);
    // console.log("Discount: " + DiscountAmount);

    // Chcking if the Amount is Greater than Taxable Amount
    let totalTax = 0;
    if (DiscountAmount >= TaxableAmount) {
      // Tax will be set to 0
      let Calculated_Updated_Tax = 0;
      let Calculated_Updated_Total =
        TaxableAmount +
        NonTaxableAmount -
        DiscountAmount +
        Calculated_Updated_Tax;

      // Rounding the updated tax and total
      Calculated_Updated_Tax = +Calculated_Updated_Tax;
      Calculated_Updated_Total = +Calculated_Updated_Total;

      // Re-declaring total and tax
      let totalTax = Calculated_Updated_Tax;
      let totalAmountDis = Calculated_Updated_Total;

      // Reset the Global totalTax variable
      setState((prevState) => {
        return {
          ...prevState,
          vat_amt: totalTax,
          grand_total: totalAmountDis,
          bill_discount_percentage: DiscountPercentage,
          bill_discount_amt: DiscountAmount,
        };
      });
    } else {
      // Else part here
      let Calculated_Percentage = (DiscountAmount / TaxableAmount) * 100;
      // console.log(Calculated_Percentage);

      // Declaring Variable for Tax
      let Calculated_Updated_Tax = 0;

      // Looping through the items
      state.QuotationDetails.map((i) => {
        // Finding required variables

        let Product_Gross_Amount = i.rate * i.quantity;

        let Product_Tax = i.vat_percentage;

        // Calculation starts here
        let Product_Tax_Amount =
          (Product_Gross_Amount * Calculated_Percentage) / 100;
        let Product_Final_Tax =
          ((Product_Gross_Amount - Product_Tax_Amount) / 100) * Product_Tax;
        Calculated_Updated_Tax += Product_Final_Tax;
      });

      let Calculated_Updated_Total =
        TaxableAmount +
        NonTaxableAmount -
        DiscountAmount +
        Calculated_Updated_Tax;

      // Rounding the updated tax and total
      Calculated_Updated_Tax = +Calculated_Updated_Tax;
      Calculated_Updated_Total = +Calculated_Updated_Total;

      // Re-declaring total and tax
      let totalTax = Calculated_Updated_Tax;
      let totalAmountDis = Calculated_Updated_Total;

      // Displaying updated value
      // console.log(DiscountPercentage);
      // console.log(DiscountAmount);
      setState((prevState) => {
        return {
          ...prevState,
          vat_amt: totalTax,
          grand_total: totalAmountDis,
          bill_discount_percentage: DiscountPercentage,
          bill_discount_amt: DiscountAmount,
        };
      });
    }
  };
  const handleFocus = (event) => {
    event.preventDefault();
    const { target } = event;
    const extensionStarts = target.value.lastIndexOf(".");
    target.focus();
    target.setSelectionRange(0, -1);
  };
  // getting values from customizable table box
  const enterKeyChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.type);
    if (e.key === "Enter" && e.target.name != "Bargain-btn") {
      if (e.target.type != "submit") {
        if (e.target.form) {
          var form = e.target.form;
          var index = Array.prototype.indexOf.call(form, e.target);
          if (e.target.type == "checkbox") {
            if (e.target.name == "Inclusive") {
            } else {
              form.elements[index + 1].focus();
            }
          } else if (e.target.type == "text" || e.target.type == "number") {
            if (
              e.target.name == "category" ||
              e.target.name == "CrOrDr" ||
              e.target.name == "product_name"
            ) {
              form.elements[index + 4].focus();
            } else {
              console.log("entered");
              form.elements[index + 2].focus();
            }
          } else if (e.target.type == "button") {
          } else if (e.target.type == "textarea") {
            console.log("idbecmon");
            form.elements[index + 3].focus();
          } else {
            form.elements[index + 2].focus();
          }
        } else {
          console.log("lets crack");
        }

        e.preventDefault();
      }
    }
  };
  const handleClear = () => {};
  const handleSubmit = async (e, is_print) => {
    e.preventDefault();
    // let validate = await handleValidation();
    // if (validate) {
    setState({ ...state, submitLoading: true });
    let url = createQuotationUrl;
    if (id) {
      url = editQuotationUrl + id;
    }
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        quotation_details: state.QuotationDetails,
        quotation_number: state.quotation_number,
        quotation_date: state.quotation_date,
        valid_till: state.valid_till,
        payment_type: state.payment_type,
        bill_discount_amt: state.bill_discount_amt,
        bill_discount_percentage: state.bill_discount_percentage,
        gross_amt: state.gross_amt,
        vat_percentage: 1,
        net_total: state.net_total,
        grand_total: state.grand_total,
        type: "quotation_invoice",
        order_no: state.order_no,
        vat_amt: state.vat_amt,
        company_id: company_id,
        customer_id: state.customer_id,
        terms_and_conditions: state.terms_and_conditions,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({ ...state, submitLoading: false });
          handleClear();

          if (is_print) {
            swal({
              title: "Successfully Submitted",
              text: response.message,
              icon: "success",
            }).then(function () {
              setPrint({ print: true, invoice_id: response.data.id });
              if (id) {
                setTimeout(() => {
                  navigate("/dashboard/quotations");
                }, 2000);
              }
            });
            setState({
              data: [{ unit: 1 }, { unit: 2 }],
              QuotationDetails: [
                {
                  product: "",
                  description: "",
                  rate: "",
                  quantity: "",
                  vat_percentage: 15,
                  vat_amount: "",
                  net_total: "",
                  gross_amount: 0,
                },
              ],
              customer_id: "",
              terms_and_conditions: "",
              quotation_number: "",
              order_no: "",
              quotation_date: today_date(),
              valid_till: today_date(),
              bill_discount_percentage: 0,
              bill_discount_amt: 0.0,
              gross_amt: 0.0,
              vat_amt: 0.0,
              net_total: 0.0,
              grand_total: 0.0,
              payment_type: "credit",
              customer_list: [],
              unit_list: [],

              productList: [],
              product: "",
            });
            fetchCustomerData();
            fetchQuotationNumber();
            fetchUnitList();
            fetchProductList();
          } else {
            swal({
              title: "Successfully Submitted",
              text: response.message,
              icon: "success",
            }).then(function () {
              if (id) {
                navigate("/dashboard/quotations");
              } else {
                window.location.reload();
              }
            });
          }
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
        }

        //  else {
        //   let form_errors = state.form_errors;
        //   if ((response.message = "Brand name already exist.")) {
        //     form_errors["BrandName"] = "Brand name already exist";
        //     setState({ ...state, submitLoading: false, form_errors });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };
  console.log(state);
  // Calculate Bill Discount Function Ends Here

  let title = "Create Quotation";
  if (id) {
    title = "Edit Quotation";
  }
  if (state.loading) {
    return <div>loading</div>;
  } else {
    return (
      <Container>
        <TopHeaderCreate
          title={title}
          buttonTitle="Save"
          handleSubmit={(e) => handleSubmit(e, true)}
          saveButtonOption={[
            {
              name: "Save",
              icon: "../../images/saveMore/save.svg",
              onClick: (e) => handleSubmit(e),
            },
            {
              name: "Save and Print",
              icon: "../../images/saveMore/print.svg",
              onClick: (e) => handleSubmit(e, true),
            },
          ]}
        />
        <FormContainer>
          <Form>
            <TopGroup>
              <CardContainer>
                <DateGroup>
                  <DateLabel> Date:</DateLabel>
                  <StyledDate
                    className="top-input"
                    fullWidth
                    id="quotation_date"
                    name="quotation_date"
                    size="small"
                    variant="outlined"
                    type="date"
                    value={state.quotation_date}
                    onChange={(e) => handleChange(e)}
                  />
                </DateGroup>
                <InputGroup>
                  <Label>Voucher No:</Label>
                  <InputButtonContainer>
                    <StyledInput
                      style={{ width: "100%", marginRight: "5px" }}
                      className="top-input"
                      fullWidth
                      id="quotation_number"
                      name="quotation_number"
                      size="small"
                      variant="outlined"
                      value={state.quotation_number}
                      onChange={(e) => handleChange(e)}
                    />
                  </InputButtonContainer>
                  <DateContainer>
                    {" "}
                    <DateLabel className="valid"> Valid Till:</DateLabel>
                    <StyledInput
                      style={{ width: "65%" }}
                      className="top-input"
                      fullWidth
                      id="valid_till"
                      name="valid_till"
                      size="small"
                      variant="outlined"
                      type="date"
                      value={state.valid_till}
                      onChange={(e) => handleChange(e)}
                    />
                  </DateContainer>
                </InputGroup>
              </CardContainer>

              <CardContainer>
                <TopInputSelect
                  label="Customer"
                  name="customer_id"
                  value={state.customer_id}
                  list={state.customer_list}
                  state={state}
                  handleAutoComplete={handleAutoComplete}
                />
              </CardContainer>
            </TopGroup>
          </Form>
        </FormContainer>
        <QuotationDetails
          state={state}
          handleAutoComplete={handleAutoComplete}
          handleInputChangeDetails={handleInputChangeDetails}
          removeSerialItemCode={removeSerialItemCode}
          handleSerialItemCode={handleSerialItemCode}
          addSerialItemCode={addSerialItemCode}
          addQuotationDetails={addQuotationDetails}
          removeQuotationDetails={removeQuotationDetails}
          handleChangeDetails={handleChangeDetails}
          handleAutoCompleteDetails={handleAutoCompleteDetails}
          enterKeyChange={enterKeyChange}
        />
        <BottomContainer>
          <BottomLeftContainer>
            <BottomInputContainer>
              <BottomInputGroup>
                {/* <InputGroup style={{ marginBottom: "10px" }}>
                <Label>TDS:</Label>
                <StyledInput
                  className="top-input"
                  fullWidth
                  id="name"
                  name="name"
                  size="small"
                  variant="outlined"
                  value={state.name}
                  // onChange={(e) => handleChange(e)}
                />
              </InputGroup> */}
                <InputGroup>
                  <StyledInput
                    fullWidth
                    id="terms_and_conditions"
                    name="terms_and_conditions"
                    size="small"
                    variant="outlined"
                    value={state.terms_and_conditions}
                    placeholder="Terms and Conditions (Optional)"
                    multiline
                    onChange={(e) => handleChange(e)}
                  />
                </InputGroup>
              </BottomInputGroup>
            </BottomInputContainer>
          </BottomLeftContainer>
          <BottomRightContainer>
            <AmountTextLeftContainer>
              <AmountTextLeftLabel>Bill Discount:</AmountTextLeftLabel>
              <AmountLeftInputContainer>
                <AmountTextInput
                  onFocus={handleFocus}
                  fullWidth
                  id="bill_discount_percentage"
                  name="bill_discount_percentage"
                  size="small"
                  variant="outlined"
                  value={
                    state.bill_discount_percentage
                      ? state.bill_discount_percentage
                      : 0
                  }
                  onChange={(e) => handleChange(e)}
                  label="%"
                />
              </AmountLeftInputContainer>
              <AmountLeftInputContainer>
                <AmountTextInput
                  onFocus={handleFocus}
                  fullWidth
                  id="bill_discount_amt"
                  name="bill_discount_amt"
                  size="small"
                  variant="outlined"
                  value={
                    state.bill_discount_amt ? state.bill_discount_amt : 0.0
                  }
                  onChange={(e) => handleChange(e)}
                  label="₹"
                />
              </AmountLeftInputContainer>
            </AmountTextLeftContainer>
            <AmountContainerRight>
              <AmountTextLabel
                label="Gross Amount"
                amount={parseFloat(state.gross_amt).toFixed(2)}
              />
              <AmountTextLabel
                label="Total Discount"
                amount={parseFloat(state.bill_discount_amt).toFixed(2)}
              />
              <AmountTextLabel
                label="Total VAT Amount"
                amount={parseFloat(state.vat_amt).toFixed(2)}
              />
              <AmountTextLabel
                label="Net Total"
                amount={parseFloat(state.net_total).toFixed(2)}
              />
              <AmountTextLabel
                label="Grand Total"
                amount={
                  state.grand_total
                    ? parseFloat(state.grand_total).toFixed(2)
                    : "0.00"
                }
              />
            </AmountContainerRight>
          </BottomRightContainer>
        </BottomContainer>
        {showPrint.print && (
          <PrintTemplate
            company_id={company_id}
            invoice_id={showPrint.invoice_id}
            showPrint={showPrint.print}
            handlePrintCancel={handlePrintCancel}
            setPrint={setPrint}
            invoice_type="quotation_invoice"
            print_template={print_template}
          />
        )}
      </Container>
    );
  }
};

export default CreateQuotation;

const Container = styled.div`
  max-width: 98%;
  margin: 0 auto;
`;
const FormContainer = styled.div`
  margin-top: 5px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  padding: 15px;
`;
const Form = styled.form``;
const TopGroup = styled.div`
  display: grid;
  grid-template-columns: 0.4fr;
  grid-gap: 1vw;
`;
const CardContainer = styled.div``;
const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-start;
`;
const DateGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 50%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &.payment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 63%;
  }
  &.payment.amount {
    width: 35%;
  }
  display: flex;
  align-items: center;
  width: 37%;
  border-radius: 3px;
  border: 1px solid;
  padding: 0 10px;
  margin-bottom: 10px;
`;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 50%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &.payment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 63%;
  }
  &.payment.amount {
    width: 35%;
  }
  display: flex;
  align-items: center;
  width: 100%;
`;
const DateLabel = styled.p`
  &.payment {
    width: 100%;
  }
  &.valid {
    width: 25%;
  }
  width: 20%;
  font-size: 12px;
  text-transform: capitalize;
`;
const Label = styled.p`
  &.payment {
    width: 100%;
  }
  width: 21%;
  font-size: 12px;
  text-transform: capitalize;
`;
const StyledDate = styled(TextField)`
  input::-webkit-datetime-edit-fields-wrapper {
    font-size: 12px;
  }
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 80%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 70px;
    max-height: 70px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
  input {
    padding: 5.5px 15px;
  }
`;
const StyledInput = styled(TextField)`
  input::-webkit-datetime-edit-fields-wrapper {
    font-size: 12px;
  }
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 50%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 70px;
    max-height: 70px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #000;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
  input {
    padding: 5.5px 14px;
  }
`;
const CheckboxGroup = styled.div`
  &.payment {
    margin-top: 10px;
    span {
      font-weight: bold;
      font-size: 16px;
    }
  }
  width: 30%;
  margin-left: 10px;
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiTypography-body1 {
    font-size: 12px;
    font-family: "poppinsregular";
  }
`;
const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    padding: 0;
    margin-right: 5px;
    /* margin-bottom: 5px; */
  }
  &.PrivateSwitchBase-root-1 {
    padding: 0;
    /* margin-bottom: 5px; */
  }
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #000;
  }
  &.MuiCheckbox-root {
    padding: 0;
    margin-right: 5px;
  }
  &.MuiTypography-body1 {
    font-size: 5px;
  }
`;
const BottomContainer = styled.div`
  background: #fff;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-column-gap: 1vw;
`;
const BottomLeftContainer = styled.div``;
const BottomRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const AmountContainerLeft = styled.div`
  width: 48%;
`;
const AmountContainerRight = styled.div`
  width: 49%;
  padding: 15px;
  background: #f1f1f1;
  border-radius: 5px;
`;
const AmountTextLeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 40%;
  margin-right: 10px;
  &:last-child {
    margin: 0;
  }
`;
const AmountTextLeftLabel = styled.p`
  font-weight: bold;
  font-size: 14px;
  padding: 8px 0;
`;
const AmountTextInput = styled(TextField)`
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3.5px 14px;
  }
  input {
    color: #000;
    padding: 3.5px 14px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 30px;
    max-height: 30px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #000;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
const ShippingTaxButton = styled.button`
  cursor: pointer;
  background: inherit;
  border: 0;
  color: blue;
`;
const AmountLeftInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35%;
  position: relative;
  &.shipping-charge {
    width: 50%;
  }
`;
const DiscountToItemsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const DiscountToItemsButton = styled.button`
  cursor: pointer;
  background: #fff;
  background: #000;
  color: #fff;
  width: 50%;
  height: 30px;
  border-radius: 3px;
  border: 0;
`;
const CustomFormatButton = styled.button`
  background: inherit;
  border: 0;
  color: blue;
  padding: 0;
  cursor: pointer;
`;
const InputButtonContainer = styled.div`
  width: 25%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 25px;
`;
const TableSettingsButton = styled.button`
  cursor: pointer;
  border: 0;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  border-radius: 3px;
`;
const TableSettingsButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;
const StyledSettingsIcon = styled(SettingsIcon)`
  margin-right: 5px;
`;
const BottomInputContainer = styled.div`
  display: grid;
  grid-column-gap: 1vw;
`;
const BottomInputGroup = styled.div``;
const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;
