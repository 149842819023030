import * as base from "../settings";

export const customersUrl = base.BASE_URL + "customers/view";

export const createCustomerUrl = base.BASE_URL + "customers/create";

export const deleteCustomerUrl = base.BASE_URL + "customers/delete/";

export const editCustomerUrl = base.BASE_URL + "customers/edit/";

export const userLoginUrl = base.BASE_URL + "users/auth";

export const userLogoutUrl = base.BASE_URL + "users/logout";

export const CreateUser = base.BASE_URL + "users/create";

export const viewUser = base.BASE_URL + "users/view";

export const editUser = base.BASE_URL + "users/edit/";

export const downloadDatabaseUrl = base.BASE_URL + "users/download-database";
