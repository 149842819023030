import * as base from "../settings";

export const customersUrl = base.BASE_URL + "customers/view";

export const createCustomerUrl = base.BASE_URL + "customers/create";

export const deleteCustomerUrl = base.BASE_URL + "customers/delete/";

export const editCustomerUrl = base.BASE_URL + "customers/edit/";

export const viewCustomerUrl = base.BASE_URL + "customers/customer/";
