import React from "react";
import styled from "styled-components/macro";

const TopHeader = (props) => {
  return (
    <Header>
      <Heading>{props.title}</Heading>
      <HeaderRight>
        <CreateButton onClick={props.onClick}>{props.buttonTitle}</CreateButton>
      </HeaderRight>
    </Header>
  );
};

export default TopHeader;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 24px;
`;
const HeaderRight = styled.div`
  display: flex;
`;
const CreateButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  padding: 8px 20px 8px 32px;
  color: #000;
  border: 0;
  border-radius: 50px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  border: 1px solid;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  &:after {
    content: "";
    width: 14px;
    height: 2px;
    background: #000;
    position: absolute;
    margin: auto 0;
    left: 10px;
    top: 0px;
    bottom: 0;
  }
  &:before {
    content: "";
    width: 2px;
    height: 14px;
    background: #000;
    position: absolute;
    margin: auto 0;
    left: 16px;
    top: 0;
    bottom: 0;
  }
  &:hover {
    opacity: 0.8;
  }
`;
