// export const URL = "http://localhost:8000/";
// export const BASE_URL = "http://localhost:8000/api/v1/";
// export const MEDIA_URL = "http://localhost:8000";

// export const URL = "https://www.api.otaibi.vikncodes.com/";
// export const BASE_URL = "https://www.api.otaibi.vikncodes.com/api/v1/";
// export const MEDIA_URL = "https://www.api.otaibi.vikncodes.com";

export const URL = "https://www.api.saadaljuhani.com/";
export const BASE_URL = "https://www.api.saadaljuhani.com/api/v1/";
export const MEDIA_URL = "https://www.api.saadaljuhani.com";


// test server
// export const URL = "https://www.api.otaibi.vikncodes.in/";
// export const BASE_URL = "https://www.api.otaibi.vikncodes.in/api/v1/";
// export const MEDIA_URL = "https://www.api.otaibi.vikncodes.in";
