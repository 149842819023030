import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../slices/user/userSlice";
import companyDetailsReducer from "../slices/companyDetails/companyDetailsSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    companyDetails: companyDetailsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: false,
});
