import { Switch, withStyles } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import swal from "sweetalert";
import { editCompanyUrl } from "../../api/CompanyAPI";
import {
  CreateUser,
  downloadDatabaseUrl,
  editUser,
  userLogoutUrl,
  viewUser,
} from "../../api/UserAPI";
import { dataURLtoFile } from "../../functions/utils";
import {
  companyDetailsLogoutSuccess,
  companyDetailsSuccess,
} from "../../slices/companyDetails/companyDetailsSlice";
import { accessToken, logoutSuccess } from "../../slices/user/userSlice";
import CompanyBox from "./components/CompanyBox";
import UserBox from "./components/UserBox";
import UserList from "./components/UserList";

const Settings = () => {
  const {
    CompanyName,
    CompanyLogo,
    id,
    PhoneNumber,
    State,
    StreetName,
    CityName,
    BuildingNo,
    VATNo,
    CRNo,
    Country,
    Email,
  } = useSelector((state) => state.companyDetails);
  const access_token = useSelector(accessToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCreateForm, setCreateForm] = useState(false);
  const [showCreateUserForm, setCreateUserForm] = useState(false);
  const [showEdit, setEdit] = useState(false);
  const [showEditId, setEditId] = useState(0);
  const AntSwitch = muiStyled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [state, setState] = useState({
    photo: "",
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    phone: "",
    user_data: [],
    counrty_list: [{ name: "Saudi Arabia", value: "Saudi Arabia" }],
    state_list: [
      { name: "Al-Bahah", value: "Al-Bahah" },
      { name: "Al-Jawf", value: "Al-Jawf" },
      { name: "Aseer", value: "Aseer" },
      { name: "Eastern", value: "Eastern" },
      { name: "Ha,il", value: "Ha,il" },
      { name: "Jizan", value: "Jizan" },
      { name: "Midinah", value: "Midinah" },
      { name: "Makkah", value: "Makkah" },
      { name: "Najran", value: "Najran" },
      { name: "Qassim", value: "Qassim" },
      { name: "Riyadh", value: "Riyadh" },
      { name: "Tabuk", value: "Tabuk" },
    ],
    CompanyName: CompanyName,
    CompanyLogo: CompanyLogo,
    PhoneNumber: PhoneNumber,
    State: State,
    StreetName: StreetName,
    CityName: CityName,
    BuildingNo: BuildingNo,
    VATNo: VATNo,
    CRNo: CRNo,
    Country: Country,
    Email: Email,
  });

  const handleSwitch = () => {};
  const clearCustomer = (e) => {
    setCreateForm(false);
  };
  const clearUser = (e) => {
    setState({
      ...state,
      username: "",
      phone: "",
      firstname: "",
      lastname: "",
      password: "",
      submitLoading: false,
    });
    setCreateUserForm(false);
  };
  const handleCreateButton = async (e) => {
    await fetch(`${editCompanyUrl}${id}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        company_id: id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({
            ...state,
            user_data: response.data,
            username: "",
            firstname: "",
            lastname: "",
            password: "",
            submitLoading: false,
            loading: false,
          });
          setCreateUserForm(false);
        }
      });
    handleClear();
    setCreateForm(!showCreateForm);
    setEdit(false);
  };
  const handleCreateUserButton = (e, id) => {
    if (id) {
      setEditId(id);
      let user = { ...state.user_data.filter((i) => i.user === id)[0] };
      setState({
        ...state,
        photo: user.ProfilePic,
        phone: user.phone,
        username: user.user_name,
        firstname: user.first_name,
        lastname: user.last_name,
      });
      setEdit(true);
    } else {
      handleClear();
      setEdit(false);
    }
    setCreateUserForm(!showCreateUserForm);
  };
  const handleClear = () => {
    setState({
      ...state,
      phone: "",
      photo: "",
      MobileNo: "",
      FirstName: "",
      LastName: "",
      Address1: "",
      Address2: "",
      CardNumber: "",
      CardTypeID: "",
      CardStatusID: "",
      AccountLedgerID: "",
      password: "",
      confirm_password: "",
    });
    setCreateForm(false);
    setEdit(false);
  };
  const handleLogout = () => {
    fetch(userLogoutUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    dispatch(logoutSuccess());
    dispatch(companyDetailsLogoutSuccess());

    navigate("/");
  };
  useEffect(async () => {
    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleAutoComplete = (e, v, n) => {
    // if don't have a value the state will be empty string

    setState({ ...state, [n]: v.name });
  };
  const handleCreateUser = async (e) => {
    e.preventDefault();
    // let validate = await handleValidation();
    // if (validate) {
    setState({ ...state, submitLoading: true });
    let photo = state.photo;
    if (typeof state.photo === "string") {
      photo = null;
    }
    var formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }
    formData.append("companyid", id);
    formData.append("username", state.username);
    formData.append("phone", state.phone);
    formData.append("firstname", state.firstname);
    formData.append("lastname", state.lastname);
    formData.append("password", state.password);
    formData.append("confirm_password", state.confirm_password);
    fetch(CreateUser, {
      method: "POST",
      headers: {
        // "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          swal({
            title: "Successfully Submitted",
            text: response.message,
            icon: "success",
          });
          fetchData();
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
          setState({ ...state, submitLoading: false });
        }
        //  else {
        //   let form_errors = state.form_errors;
        //   if ((response.message = "Brand name already exist.")) {
        //     form_errors["BrandName"] = "Brand name already exist";
        //     setState({ ...state, submitLoading: false, form_errors });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };
  const handleEditCompany = async (e) => {
    e.preventDefault();
    // let validate = await handleValidation();
    // if (validate) {
    setState({ ...state, submitLoading: true });
    let photo = state.CompanyLogo;
    if (typeof state.CompanyLogo === "string") {
      photo = null;
    }

    var formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }
    formData.append("CompanyName", state.CompanyName);
    formData.append("PhoneNumber", state.PhoneNumber);
    formData.append("State", state.State);
    formData.append("StreetName", state.StreetName);
    formData.append("CityName", state.CityName);
    formData.append("BuildingNo", state.BuildingNo);
    formData.append("VATNo", state.VATNo);
    formData.append("CRNo", state.CRNo);
    formData.append("Country", state.Country);
    formData.append("Email", state.Email);
    fetch(`${editCompanyUrl}${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          dispatch(
            companyDetailsSuccess({
              ...response.data,
            })
          );
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
          });
          fetchData();
        } else {
          swal({
            title: "Faild",
            text: response.message,
            icon: "warning",
          });
        }
        //  else {
        //   let form_errors = state.form_errors;
        //   if ((response.message = "Brand name already exist.")) {
        //     form_errors["BrandName"] = "Brand name already exist";
        //     setState({ ...state, submitLoading: false, form_errors });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };
  const fetchData = async () => {
    await fetch(viewUser, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        company_id: id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({
            ...state,
            user_data: response.data,
            username: "",
            firstname: "",
            lastname: "",
            password: "",
            submitLoading: false,
            loading: false,
          });
          setCreateUserForm(false);
        }
      });
  };
  const fileChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleEditUser = async (e, id) => {
    e.preventDefault();
    setState({ ...state, submitLoading: true });
    let photo = state.photo;
    if (typeof state.photo === "string") {
      photo = null;
    }
    var formData = new FormData();
    if (photo) {
      formData.append("photo", photo);
    }
    formData.append("username", state.username);
    formData.append("phone", state.phone);
    formData.append("firstname", state.firstname);
    formData.append("lastname", state.lastname);
    formData.append("password", state.password);
    formData.append("confirm_password", state.confirm_password);
    fetch(`${editUser + id}`, {
      method: "POST",
      headers: {
        // "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },

      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({ ...state, submitLoading: false });
          handleClear();
          fetchData();
          swal({
            title: "Success",
            text: response.message,
            icon: "success",
          });
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
          setState({ ...state, submitLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(state);
  return (
    <Container>
      <Header>
        <Heading>Settings</Heading>
        <HeaderRight>
          <LogoutButton onClick={handleLogout}>
            <LogoutImage src="../images/icons/logout.svg" />
            <LogoutText>Logout</LogoutText>
          </LogoutButton>
        </HeaderRight>
      </Header>
      <OrganizationContainer>
        <SubHeading>Organization Profile</SubHeading>
        <OrganizationDetails>
          <OrganizationInfo>
            <OrganizationImage src={CompanyLogo} />
            <OrganizationText>{CompanyName}</OrganizationText>
          </OrganizationInfo>
          <EditButton onClick={() => handleCreateButton()}>
            <Image src="../images/icons/edit.svg" />
          </EditButton>
        </OrganizationDetails>
      </OrganizationContainer>
      {/* <OrganizationContainer>
        <SubHeading>Preferences</SubHeading>
        <SwitchContainer>
          <SwitchLabel>Notification</SwitchLabel>
          <AntSwitch
            checked={true}
            onChange={() => handleSwitch()}
            name="view_permission"
          />
        </SwitchContainer>
      </OrganizationContainer> */}
      <OrganizationContainer>
        <CreateUserContainer>
          <SubHeading>Users</SubHeading>
          <CreateButtonContainer onClick={handleCreateUserButton}>
            <CreateButton>New User</CreateButton>
          </CreateButtonContainer>
        </CreateUserContainer>
        <UserContainer>
          {state.user_data.length ? (
            state.user_data.map((i, index) => (
              <UserList
                key={index}
                data={i}
                handleCreateUserButton={handleCreateUserButton}
              />
            ))
          ) : (
            <UserNotFound>user not found</UserNotFound>
          )}
        </UserContainer>
      </OrganizationContainer>
      <DatabaseBackupContainer>
        <CreateUserContainer>
          <SubHeading>Database Backup</SubHeading>
        </CreateUserContainer>
        <DownloadDatabase href={downloadDatabaseUrl}>
          Download Database
        </DownloadDatabase>
      </DatabaseBackupContainer>
      {showCreateForm && (
        <CompanyBox
          state={state}
          showEditId={showEditId}
          clearCustomer={clearCustomer}
          handleAutoComplete={handleAutoComplete}
          handleChange={handleChange}
          handleEditCompany={handleEditCompany}
          fileChange={fileChange}
        />
      )}
      {showCreateUserForm && (
        <UserBox
          state={state}
          showEdit={showEdit}
          showEditId={showEditId}
          clearUser={clearUser}
          onClick={handleCreateUserButton}
          handleCreateUser={handleCreateUser}
          handleChange={handleChange}
          handleEditUser={handleEditUser}
          fileChange={fileChange}
        />
      )}
    </Container>
  );
};

export default Settings;
const Container = styled.div``;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 24px;
`;
const HeaderRight = styled.div`
  display: flex;
`;
const LogoutButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  padding: 8px 20px 8px 32px;
  color: #000;
  border: 0;
  border-radius: 50px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`;
const OrganizationContainer = styled.div`
  padding: 10px 0;
`;
const SubHeading = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`;
const OrganizationDetails = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const OrganizationImage = styled.img`
  width: 70px;
`;
const OrganizationText = styled.p`
  margin-left: 10px;
  font-weight: bold;
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SwitchLabel = styled.p`
  margin-right: 10px;
`;
const CreateUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CreateButtonContainer = styled.div``;
const CreateButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  padding: 8px 20px 8px 32px;
  color: #000;
  border: 0;
  border-radius: 50px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  border: 1px solid;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  &:after {
    content: "";
    width: 14px;
    height: 2px;
    background: #000;
    position: absolute;
    margin: auto 0;
    left: 10px;
    top: 0px;
    bottom: 0;
  }
  &:before {
    content: "";
    width: 2px;
    height: 14px;
    background: #000;
    position: absolute;
    margin: auto 0;
    left: 16px;
    top: 0;
    bottom: 0;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const UserContainer = styled.div`
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 3px;
  height: 49vh;
  overflow-y: scroll;
`;
const OrganizationInfo = styled.div`
  display: flex;
  align-items: center;
`;
const EditButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;
const Image = styled.img`
  width: 25px;
`;
const LogoutText = styled.p`
  margin-left: 10px;
`;
const LogoutImage = styled.img`
  width: 25px;
`;
const UserNotFound = styled.div`
  padding: 10px;
  text-transform: capitalize;
  text-align: center;
`;
const DatabaseBackupContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const DownloadDatabase = styled.a`
  color: #fff;
  padding: 10px 20px;
  border-radius: 3px;
  background: #000;
  font-weight: bold;
`;
