import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("companyDetails")
  ? JSON.parse(localStorage.getItem("companyDetails"))
  : {
      id: "",
      CompanyName: "",
      CompanyLogo: "",
      PhoneNumber: "",
      State: "",
      StreetName: "",
      CityName: "",
      BuildingNo: "",
      VATNo: "",
      CRNo: "",
      Email: "",
      Country: "",
      print_template: ""
    };

const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    companyDetailsSuccess: (state, action) => {
      state.id = action.payload.id;
      state.CompanyName = action.payload.CompanyName;
      state.CompanyLogo = action.payload.CompanyLogo;
      state.PhoneNumber = action.payload.PhoneNumber;
      state.State = action.payload.State;
      state.StreetName = action.payload.StreetName;
      state.CityName = action.payload.CityName;
      state.BuildingNo = action.payload.BuildingNo;
      state.VATNo = action.payload.VATNo;
      state.CRNo = action.payload.CRNo;
      state.Email = action.payload.Email;
      state.Country = action.payload.Country;
      state.print_template = action.payload.print_template;

      localStorage.setItem("companyDetails", JSON.stringify(action.payload));
    },
    companyDetailsLogoutSuccess: (state, action) => {
      state.id = null;
      state.CompanyName = null;
      state.CompanyLogo = null;
      state.PhoneNumber = null;
      state.State = null;
      state.StreetName = null;
      state.CityName = null;
      state.BuildingNo = null;
      state.VATNo = null;
      state.CRNo = null;
      state.Email = null;
      state.Country = null;
      state.print_template = null;
      localStorage.removeItem("companyDetails");
    },
  },
});

export const { companyDetailsSuccess, companyDetailsLogoutSuccess } =
  companyDetailsSlice.actions;

export const selectCompanyName = (state) => state.companyDetails.CompanyName;
export const selectCompanyID = (state) => state.companyDetails.CompanyID;
// export const selectUserEmail = (state) => state.companyDetails.email;
// export const selectUserPhoto = (state) => state.companyDetails.photo;
// export const accessToken = (state) => state.companyDetails.access;
// export const selectAuthenticated = (state) => state.companyDetails.isAuth;

export default companyDetailsSlice.reducer;
