import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import swal from "sweetalert";
import { statementUrl, updateStatementUrl } from "../../api/StatementAPI";
import { customersUrl } from "../../api/UserAPI";
import PrintStatement from "../../components/PrintStatement";
import Table from "../../components/Table";
import TopHeader from "../../components/TopHeader";
import TopHeaderCreate from "../../components/TopHeaderCreate";
import { get_index, today_date } from "../../functions/utils";
import { accessToken } from "../../slices/user/userSlice";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import StatementTable from "../../components/StatementTable";

const Statements = () => {
  const companyDetails = useSelector((state) => state.companyDetails);
  const company_id = companyDetails.id;
  const access_token = useSelector(accessToken);
  const [state, setState] = useState({
    data: [],
    customer_list: [],
    from_date: today_date(),
    to_date: today_date(),
    status: "all",
    customer_id: "",
    discount_sum: "",
    grand_total_sum: "",
    gross_amt_sum: "",
    vat_amt_sum: "",
    status_list: [
      { id: "cash", name: "Paid" },
      { id: "credit", name: "Unpaid" },
      { id: "all", name: "All" },
    ],
    random: 0,
  });
  const [showPrint, setPrint] = useState(false);
  const xlRef = useRef(null);
  const downloadXL = () => {
    console.log("hello");
    console.log(xlRef.current.handleDownload);
    xlRef.current.handleDownload();
  };

  useEffect(async () => {
    await fetchCustomerData();
  }, []);
  const [showOptionList, setOptionList] = useState(0);
  const handleOption = (id) => {
    if (id === showOptionList) {
      setOptionList(0);
    } else {
      console.log(id);
      setOptionList(id);
    }
  };
  const fetchCustomerData = async () => {
    await fetch(`${customersUrl}/${company_id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      // body: JSON.stringify({
      //   CompanyID: CompanyID,
      // }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              customer_list: response.data,
            };
          });
        }
      });
  };

  const handleStatus = async (sales_id, status) => {
    if (status === "cash") {
      status = "credit";
    } else {
      status = "cash";
    }
    console.log(sales_id, status);
    await fetch(updateStatementUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        sales_id: sales_id,
        status: status,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          swal({
            title: "Successfully Updated",
            text: "",
            icon: "success",
          });
          handleSubmit();
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
        }
      });
  };

  const handleSubmit = async () => {
    await fetch(statementUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        customer_id: state.customer_id,
        status: state.status,
        from_date: state.from_date,
        to_date: state.to_date,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState((state) => {
            return {
              ...state,
              data: response.data,
              discount_sum: response.discount_sum,
              grand_total_sum: response.grand_total_sum,
              gross_amt_sum: response.gross_amt_sum,
              vat_amt_sum: response.vat_amt_sum,
              credit_note_only: response.credit_note_only,
            };
          });
          if (response.data.length === 0) {
            swal({
              title: "Statement not found for this customer",
              text: "",
              icon: "warning",
            });
          }
        }
      });
  };
  const handleAutoComplete = (e, v, n) => {
    if (v) {
      // if customer and value exists the value will be taken
      setState({ ...state, [n]: v.id });
    } else {
      // the loyalty customer details removed from the box
      setState({ ...state, [n]: "" });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handlePrint = () => {
    setPrint(true);
    setState({ ...state, random: state.random + 1 });
  };
  const tableHead = [
    "Sl No",
    "Invoice",
    "PO No",
    "Date",
    "Gross Amount",
    "VAT",
    "Discount",
    "Total",
    "Status",
  ];
  const tableBody = [
    "voucher_number",
    "order_no",
    "sales_date",
    "gross_amt",
    "vat_amt",
    "bill_discount_amt",
    "grand_total",
    "payment_type",
  ];
  console.log(state);
  return (
    <Container>
      <ExcelButton
        style={{ display: "none" }}
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        ref={xlRef}
        filename={
          state.customer_list.filter((i) => i.id === state.customer_id)[0]
            ? state.customer_list.filter((i) => i.id === state.customer_id)[0]
                .CustomerName
            : "excel"
        }
        sheet="tablexls"
        buttonText="Download as XLS"
      />
      <TopHeaderCreate
        title="Statements"
        buttonTitle="Download"
        handleSubmit={() => handlePrint()}
        saveButtonOption={[
          {
            name: "Download",
            icon: "../../images/saveMore/save.svg",
            onClick: () => setPrint(true),
          },
          {
            name: "Download Excel",
            icon: "../../images/saveMore/print.svg",
            onClick: (e) => downloadXL(e),
          },
          // {
          //   name: "Save and Send",
          //   icon: "../../images/saveMore/send.svg",
          //   onClick: (e) => alert("option not available"),
          // },
          // {
          //   name: "Save and Share",
          //   icon: "../../images/saveMore/share.svg",
          //   onClick: (e) => alert("option not available"),
          // },
        ]}
      />
      <InputRow>
        <InputGroup>
          <Label>Customer</Label>
          <Autocomplete
            id="customer_id"
            options={state.customer_list ? state.customer_list : []}
            getOptionLabel={(option) => option.CustomerName || ""}
            name="customer_id"
            onChange={(e, v) => handleAutoComplete(e, v, "customer_id")}
            value={
              state.customer_list
                ? state.customer_list[
                    get_index(state.customer_list, state.customer_id)
                  ]
                : ""
            }
            renderInput={(params) => (
              <StyleTextInput
                {...params}
                fullWidth
                size="small"
                id="customer_id"
                name="customer_id"
                value={state.customer_id}
                variant="outlined"
              />
            )}
          />
        </InputGroup>
        <InputGroup>
          <Label>Status</Label>
          <Autocomplete
            id="status"
            options={state.status_list ? state.status_list : []}
            getOptionLabel={(option) => option.name || ""}
            name="status"
            onChange={(e, v) => handleAutoComplete(e, v, "status")}
            value={
              state.status_list
                ? state.status_list[get_index(state.status_list, state.status)]
                : ""
            }
            renderInput={(params) => (
              <StyleTextInput
                {...params}
                fullWidth
                size="small"
                id="status"
                name="status"
                value={state.status}
                variant="outlined"
              />
            )}
          />{" "}
        </InputGroup>
        <InputGroup>
          <Label>From</Label>
          <StyleTextInput
            className="top-input"
            fullWidth
            id="from_date"
            name="from_date"
            size="small"
            variant="outlined"
            type="date"
            value={state.from_date}
            onChange={(e) => handleChange(e)}
          />
        </InputGroup>
        <InputGroup>
          <Label>To</Label>
          <StyleTextInput
            className="top-input"
            fullWidth
            id="to_date"
            name="to_date"
            size="small"
            variant="outlined"
            type="date"
            value={state.to_date}
            onChange={(e) => handleChange(e)}
          />
        </InputGroup>
        <SearchButton type="button" onClick={handleSubmit}>
          Search
        </SearchButton>
      </InputRow>
      <Footer>
        {state.data.length > 0 ? (
          <StatementTable
            state={state}
            tableHead={tableHead}
            tableBody={tableBody}
            options={true}
            showOptionList={showOptionList}
            handleOption={handleOption}
            showOptionList={showOptionList}
            options={false}
            status={true}
            credit_note_only={state.credit_note_only}
            handleStatus={handleStatus}
            handleOption={handleOption}
          />
        ) : (
          <NotFound>Please select a customer</NotFound>
        )}
        <table id="table-to-xls" style={{ display: "none" }}>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Invoice</th>
              <th>PO No</th>
              <th>Date</th>
              <th>Gross Amount</th>
              <th>VAT</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {state.data.map((i, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{i.voucher_number}</td>
                <td>{i.order_no}</td>
                <td>{i.sales_date}</td>
                <td>{i.gross_amt}</td>
                <td>{i.vat_amt}</td>
                <td>{i.bill_discount_amt}</td>
                <td>{i.grand_total}</td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{state.gross_amt_sum}</td>
              <td>{state.vat_amt_sum}</td>
              <td>{state.discount_sum}</td>
              <td>{state.grand_total_sum}</td>
            </tr>
          </tbody>
        </table>
      </Footer>
      {showPrint && (
        <PrintStatement
          key={state.random}
          state={state}
          showPrint={showPrint}
          setPrint={setPrint}
          invoice_type="sale_invoice"
        />
      )}
    </Container>
  );
};

export default Statements;

const Container = styled.div`
  padding: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 15px;
`;
const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
  grid-gap: 1vw;
  margin-bottom: 20px;
  width: 80%;
`;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 2px !important;
  }
`;
const Label = styled.p`
  width: 100%;
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const StyleTextInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
    padding: 4.5px 14px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #959595;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
const SearchButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 10px 25px;
  background: green;
  color: #fff;
  height: fit-content;
  margin-top: auto;
  border-radius: 3px;
`;
const NotFound = styled.div`
  font-size: 20px;
  text-align: center;
  margin-top: 100px;
`;
const ExcelButton = styled(ReactHTMLTableToExcel)`
  display: none;
`;
