import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import { get_index } from "../functions/utils";

const TopInputSelect = (props) => {
  const index = get_index(props.list, props.value);
  return (
    <InputRow>
      <InputGroup>
        <Label>{props.label}:</Label>

        <Autocomplete
          id={props.name}
          options={props.list ? props.list : []}
          getOptionLabel={(option) => option.CustomerName || ""}
          name="unit"
          onChange={(e, v) => props.handleAutoComplete(e, v, props.name)}
          value={props.list && props.value ? props.list[index] : ""}
          renderInput={(params) => (
            <StyledInput
              {...params}
              className="top-input"
              fullWidth
              id={props.customer}
              name={props.customer}
              size="small"
              variant="outlined"
              value={props.value}
            />
          )}
        />
      </InputGroup>
    </InputRow>
  );
};

export default TopInputSelect;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`;

const InputGroup = styled.div`
  & svg {
    color: #000;
  }
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 60%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &.payment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 63%;
  }
  &.payment.amount {
    width: 35%;
  }
  &
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 2px;
  }
  display: flex;
  align-items: center;
  width: 100%;
`;
const Label = styled.p`
  &.payment {
    width: 100%;
  }
  width: 16%;
  font-size: 12px;
  text-transform: capitalize;
`;
const StyledInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 60%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #000;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
