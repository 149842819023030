import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { checkWordLength, getAvatar } from "../../../functions/utils";

const CompanyList = (props) => {
  const { id, CompanyName, CompanyLogo } = useSelector(
    (state) => state.companyDetails
  );
  return (
    <CompanySelectMenu showComapanyList={props.showComapanyList}>
      {props.state.companies.map((company) => (
        <CompanyListContainer key={company.id} selected={company.id === id}>
          <CompanyListGroup onClick={() => props.switchCompany(company.id)}>
            <CompanySelectImgContainer>
              {company.CompanyLogo ? (
                <CompanyImg
                  selected={company.id === id}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getAvatar(company.id);
                  }}
                  src={company.CompanyLogo}
                ></CompanyImg>
              ) : (
                <CompanyImg
                  selected={company.id === id}
                  src={getAvatar(company.id)}
                ></CompanyImg>
              )}
            </CompanySelectImgContainer>
            <CompanyNameContainer>
              <CompanyNameLabelContainer>
                <CompanyMarquee>
                  <CompanyNameLabel move={checkWordLength(company.CompanyName)}>
                    {company.CompanyName}
                  </CompanyNameLabel>
                </CompanyMarquee>
              </CompanyNameLabelContainer>
            </CompanyNameContainer>
          </CompanyListGroup>
        </CompanyListContainer>
      ))}
    </CompanySelectMenu>
  );
};

export default CompanyList;

const CompanyMarquee = styled.div`
  overflow: hidden;
  position: relative;
  background: #fefefe;
  color: #333;
  width: 100%;
  transition: all ease-in-out 0.3s;
`;

const CompanyListContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 215px;
  min-width: 215px;
  margin: 10px 0;
  border-radius: 20px;
  transition: all ease-in-out 0.3s;
  ${({ selected }) =>
    selected &&
    `
    ${CompanyMarquee} {
      background: #e0e0e0;
      transition: all ease-in-out 0.3s;
    }
    color: #000;
    background: #e0e0e0;
    border-radius: 20px;
    transition: all ease-in-out 0.3s;
  `}
  &:hover {
    ${CompanyMarquee} {
      background: #e0e0e0;
      transition: all ease-in-out 0.3s;
    }
    color: #000;
    background: #e0e0e0;
    border-radius: 20px;
    transition: all ease-in-out 0.3s;
  }
`;
const CompanySelectMenu = styled.div`
  /* display: ${({ showComapanyList }) =>
    showComapanyList ? "none" : "block"}; */
  opacity: ${({ showComapanyList }) => (showComapanyList ? "1" : "0.5")};
  transform: ${({ showComapanyList }) =>
    showComapanyList ? "translateY(0)" : "translateY(-200%)"};
  box-shadow: 0px 0px 5px 0px #dcd9d9;
  position: absolute;
  top: 60px;
  background: #fff;
  border-radius: 25px;
  padding: 8px;
  z-index: 100;
  transition: transform ease-in-out 0.5s;
  ${CompanyListContainer}:first-child {
    margin-top: 0;
  }
  ${CompanyListContainer}:last-child {
    margin-bottom: 0;
  }
`;

const CompanyListGroup = styled.div`
  display: flex;
  align-items: center;
`;

const CompanySelectImgContainer = styled.div`
  width: 30px;
  height: 30px;
  padding: 4px;
`;

const CompanyImg = styled.img`
  border: ${({ selected }) =>
    selected ? "2px solid #1fa500" : "2px solid #e5e5e5"};
  /* border: 2px solid #e5e5e5; */
  width: 90%;
  border-radius: 30px;
  display: block;
  height: 90%;
  object-fit: cover;
`;

const CompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyNameLabelContainer = styled.div`
  width: 135px;
  display: flex;
  justify-content: space-between;
  height: 17px;
  margin-left: 5px;
  font-size: 13px;
`;

const CompanyNameLabel = styled.p`
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;

  ${({ move }) =>
    move &&
    `
    width: 230%;
     -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  -moz-animation: bouncing-text 5s linear infinite alternate;
  -webkit-animation: bouncing-text 5s linear infinite alternate;
  animation: bouncing-text 10s linear infinite alternate; 
  `}
  /* -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  -moz-animation: bouncing-text 5s linear infinite alternate;
  -webkit-animation: bouncing-text 5s linear infinite alternate;
  animation: bouncing-text 10s linear infinite alternate; */
  @-moz-keyframes bouncing-text {
    0%,
    100% {
      -moz-transform: translateX(10%);
    }
  }

  @-webkit-keyframes bouncing-text {
    0% {
      -webkit-transform: translateX(10%);
    }
    100% {
      -webkit-transform: translateX(-30%);
    }
  }

  @keyframes bouncing-text {
    0% {
      -moz-transform: translateX(10%);
      -webkit-transform: translateX(10%);
      transform: translateX(10%);
    }
    100% {
      -moz-transform: translateX(-30%);
      -webkit-transform: translateX(-30%);
      transform: translateX(-30%);
    }
  }
`;

const ProfitAndLossText = styled.p`
  font-weight: bold;
  margin-left: 5px;
`;
const ProftAndLossBarContainer = styled.div``;

const ProftAndLossBarFull = styled.div`
  position: relative;
  width: 100%;
  background: #e5e5e5;
  height: 2px;
`;

const ProftAndLossBarValue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${({ percentage }) => (percentage ? percentage + "%" : "100%")};
  max-width: 100%;
  background: ${({ percentage }) => (percentage < 50 ? "red" : "green")};
`;
