import "./App.css";
import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
} from "react-router-dom";
import Home from "./screens/home/Home";
import SignIn from "./screens/auth/SignIn.js";
import Routes from "./screens/routes/Routes";
import SignUp from "./screens/auth/SignUp";
import ForgotPassword from "./screens/auth/ForgotPassword";
import PageNotFound from "./screens/home/PageNotFound";
import ForgotPasswordConfirm from "./screens/auth/ForgotPasswordConfirm";

function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" element={<Home />} /> */}
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/forgot-password-confirm/:uidb64-:token"
          element={<ForgotPasswordConfirm />}
        />
        <Route path="/dashboard/*" element={<Routes />} />
        <Route path="*" element={<PageNotFound />} />
      </Switch>
    </Router>
  );
}

export default App;
