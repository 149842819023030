import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../../components/InputField";
import ImageUpload from "../../../components/ImageUpload";

const CompanyBox = (props) => {
  //   let index_sundry = get_index(
  //     props.state.sundry_debters,
  //     props.state.AccountLedgerID
  //   );
  //   let index_cardtype = get_index(props.state.cardtypes, props.state.CardTypeID);
  //   let index_cardstatus = get_index(
  //     props.state.cardstatus,
  //     props.state.CardStatusID
  //   );
  const get_index = (list, id) => {
    let index = null;
    if (list) {
      index = list.findIndex((i) => i.name === id);
    }
    return index;
  };
  let index_state = get_index(props.state.state_list, props.state.State);
  return (
    <ModalContainer>
      <Container>
        <CloseButton type="button" onClick={() => props.clearCustomer()}>
          <CloseIcon style={{ fontSize: "15px" }} />
        </CloseButton>

        <Form>
          <ImageUpload
            onChange={props.fileChange}
            file={props.state.CompanyLogo}
            name="CompanyLogo"
          />
          <InputRow>
            <InputField
              label="Name"
              name="CompanyName"
              value={props.state.CompanyName}
              handleChange={props.handleChange}
            />
            <InputField
              label="Phone Number"
              name="PhoneNumber"
              value={props.state.PhoneNumber}
              handleChange={props.handleChange}
            />
          </InputRow>
          <InputRow>
            <InputField
              label="Email Address"
              name="Email"
              value={props.state.Email}
              handleChange={props.handleChange}
            />
          </InputRow>
          <InputRow>
            <InputGroup>
              <Label>Country</Label>
              <Autocomplete
                disabled
                id="Country"
                options={
                  props.state.counrty_list ? props.state.counrty_list : []
                }
                getOptionLabel={(option) => option.name || ""}
                name="Country"
                onChange={(e, v) => props.handleAutoComplete(e, v, "Country")}
                value={
                  props.state.counrty_list ? props.state.counrty_list[0] : ""
                }
                renderInput={(params) => (
                  <StyleTextInput
                    {...params}
                    fullWidth
                    size="small"
                    id="Country"
                    name="Country"
                    value={props.state.Country}
                    variant="outlined"
                  />
                )}
              />
            </InputGroup>
            <InputGroup>
              <Label>State</Label>
              <Autocomplete
                id="State"
                options={props.state.state_list ? props.state.state_list : []}
                getOptionLabel={(option) => option.name || ""}
                name="State"
                onChange={(e, v) => props.handleAutoComplete(e, v, "State")}
                value={
                  props.state.state_list
                    ? props.state.state_list[index_state]
                    : ""
                }
                renderInput={(params) => (
                  <StyleTextInput
                    {...params}
                    fullWidth
                    size="small"
                    id="State"
                    name="State"
                    value={props.state.State}
                    variant="outlined"
                  />
                )}
              />
            </InputGroup>
          </InputRow>

          <InputRow>
            <InputField
              label="City Name"
              name="CityName"
              value={props.state.CityName}
              handleChange={props.handleChange}
            />
            <InputField
              label="Street Name"
              name="StreetName"
              value={props.state.StreetName}
              handleChange={props.handleChange}
            />
          </InputRow>
          <InputRow>
            <InputField
              label="BuildingNo"
              name="BuildingNo"
              value={props.state.BuildingNo}
              handleChange={props.handleChange}
            />
          </InputRow>
          <InputRow>
            <InputField
              label="VAT No"
              name="VATNo"
              value={props.state.VATNo}
              handleChange={props.handleChange}
            />
            <InputField
              label="CR No"
              name="CRNo"
              value={props.state.CRNo}
              handleChange={props.handleChange}
            />
          </InputRow>

          <ButtonContainer>
            {props.showEdit ? (
              props.state.submitLoading ? (
                <Button type="button">Please wait...</Button>
              ) : (
                <Button
                  type="button"
                  onClick={(e) => props.handleEditCompany(e)}
                >
                  Edit Loyalty Customer
                </Button>
              )
            ) : props.state.submitLoading ? (
              <Button type="button">Please wait...</Button>
            ) : (
              <Button type="button" onClick={(e) => props.handleEditCompany(e)}>
                Save
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </Container>
    </ModalContainer>
  );
};

export default CompanyBox;

const ModalContainer = styled.div`
  background: rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;
const Container = styled.div`
  position: relative;
  width: 500px;
  height: 635px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 50px 26px 30px;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
`;

const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw;
  margin-bottom: 20px;
`;

const Form = styled.form``;
const Button = styled.button`
  background: #21971b;
  color: #fff;
  padding: 10px 50px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  transition: ease 0.3s;
  &:hover {
    opacity: 0.8;
    transition: ease 0.3s;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
  text-align: center;
`;
const CloseButton = styled.button`
  border: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  border-radius: 10px;
  line-height: 7px;
  background: red;
  color: #fff;
  font-weight: bold;
  transition: ease 0.3s;
  &:hover {
    opacity: 0.5;
    transition: ease 0.3s;
  }
`;
const Label = styled.p`
  width: 100%;
  font-size: 12px;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
const StyleTextInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    height: 100px;
    min-height: 169px;
    max-height: 169px;
    color: #000;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    border: 1px solid #959595;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    padding-top: 5.5px;
    padding-bottom: 5.5px;
  }
  &.MuiFormControl-fullWidth {
    width: 100%;
  }
`;
const InputGroup = styled.div`
  &
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 1px !important;
  }
  & .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  & .payment.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: 100%;
    .top-input.MuiFormControl-fullWidth {
      width: 100%;
    }
  }
  &
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 1px !important;
  }
`;
