import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import swal from "sweetalert";
import {
  createCustomerUrl,
  customersUrl,
  deleteCustomerUrl,
  editCustomerUrl,
  viewCustomerUrl,
} from "../../api/CustomerAPI";
import Table from "../../components/Table";
import TopHeader from "../../components/TopHeader";
import { accessToken } from "../../slices/user/userSlice";
import CreateCustomer from "./CreateCustomer";
import { Pagination } from "@mui/material";

const ListCustomer = () => {
  const { id } = useSelector((state) => state.companyDetails);
  const access_token = useSelector(accessToken);
  const [showEditId, setEditId] = useState(0);
  const [showCreateForm, setCreateForm] = useState(false);
  const [showEdit, setEdit] = useState(false);
  let [page, setPage] = useState(1);
  const [state, setState] = useState({
    data: [],
    total: 0,
    CustomerName: "",
    Email: "",
    PhoneNumber: "",
    State: "",
    StreetName: "",
    CityName: "",
    BuildingNo: "",
    VATNo: "",
    CRNo: "",
    Country: "Saudi Arabia",
    counrty_list: [{ name: "Saudi Arabia", value: "Saudi Arabia" }],
    state_list: [
      { name: "Al-Bahah", value: "Al-Bahah" },
      { name: "Al-Jawf", value: "Al-Jawf" },
      { name: "Aseer", value: "Aseer" },
      { name: "Eastern", value: "Eastern" },
      { name: "Ha,il", value: "Ha,il" },
      { name: "Jizan", value: "Jizan" },
      { name: "Midinah", value: "Midinah" },
      { name: "Makkah", value: "Makkah" },
      { name: "Najran", value: "Najran" },
      { name: "Qassim", value: "Qassim" },
      { name: "Riyadh", value: "Riyadh" },
      { name: "Tabuk", value: "Tabuk" },
    ],
  });
  const handleClear = () => {
    setState({
      ...state,
      CustomerName: "",
      Email: "",
      PhoneNumber: "",
      State: "",
      StreetName: "",
      CityName: "",
      BuildingNo: "",
      VATNo: "",
      CRNo: "",
    });
    setCreateForm(false);
    setEdit(false);
  };
  const handleCreateButton = (e) => {
    handleClear();
    setCreateForm(!showCreateForm);
    setEdit(false);
  };
  const [showOptionList, setOptionList] = useState(0);
  const handleOption = (id) => {
    if (id === showOptionList) {
      setOptionList(0);
    } else {
      console.log(id);
      setOptionList(id);
    }
  };
  const clearCustomer = (e) => {
    setCreateForm(false);
  };

  useEffect(async () => {
    fetchData();
  }, []);

  useEffect(async () => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    await fetch(`${customersUrl}/${id}?page=${page}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      // body: JSON.stringify({
      //   CompanyID: CompanyID,
      // }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({
            ...state,
            data: response.data,
            total: Math.ceil(response.total / 20),
            loading: false,
          });
        }
      });
  };

  const handleAutoComplete = (e, v, n) => {
    // if don't have a value the state will be empty string

    setState({ ...state, [n]: v.name });
  };
  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    // let validate = await handleValidation();
    // if (validate) {
    setState({ ...state, submitLoading: true });
    fetch(createCustomerUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        company_id: id,
        CustomerName: state.CustomerName,
        Email: state.Email,
        PhoneNumber: state.PhoneNumber,
        State: state.State,
        StreetName: state.StreetName,
        CityName: state.CityName,
        BuildingNo: state.BuildingNo,
        VATNo: state.VATNo,
        CRNo: state.CRNo,
        Country: state.Country,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({ ...state, submitLoading: false });
          handleClear();
          fetchData();
          swal({
            title: "Successfully Submitted",
            text: response.message,
            icon: "success",
          });
        } else {
          setState({ ...state, submitLoading: false });
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
        }

        //  else {
        //   let form_errors = state.form_errors;
        //   if ((response.message = "Brand name already exist.")) {
        //     form_errors["BrandName"] = "Brand name already exist";
        //     setState({ ...state, submitLoading: false, form_errors });
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };
  const handleEditSubmit = async (e, id) => {
    e.preventDefault();
    setState({ ...state, submitLoading: true });
    fetch(`${editCustomerUrl + id}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
      body: JSON.stringify({
        ...state,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({ ...state, submitLoading: false });
          handleClear();
          swal({
            title: "Successfully Submitted",
            text: response.message,
            icon: "success",
          });
          fetchData();
        } else {
          swal({
            title: "Failed",
            text: response.message,
            icon: "warning",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const editItem = (id) => {
    setEditId(id);
    fetch(`${viewCustomerUrl + id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${access_token}`,
        // "accept": "application/json"
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.StatusCode === 6000) {
          setState({
            ...state,
            ...response.data,
            loading: false,
          });
          setCreateForm(true);
          setEdit(true);
        } else {
          setState({
            ...state,
            dataSource: [],
            brandName: "",
            notes: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteItem = (id) => {
    swal({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${deleteCustomerUrl + id}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${access_token}`,
            // "accept": "application/json"
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.StatusCode === 6000) {
              swal({
                title: "Success",
                text: response.message,
                icon: "success",
              });
              fetchData();
            } else {
              swal({
                title: "failed",
                text: response.message,
                icon: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        swal("Customer not deleted");
      }
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const tableHead = [
    "Sl No",
    "Customer Name",
    "Contact",
    "Email",
    "VAT No",
    "CR No",
    "",
  ];
  const tableBody = ["CustomerName", "PhoneNumber", "Email", "VATNo", "CRNo"];
  return (
    <Container>
      <TopHeader
        title="Customer"
        buttonTitle="Add Customer"
        onClick={handleCreateButton}
      />
      <Footer>
        <Table
          page={page}
          state={state}
          tableHead={tableHead}
          tableBody={tableBody}
          options={true}
          showOptionList={showOptionList}
          handleOption={handleOption}
          editItem={editItem}
          deleteItem={deleteItem}
        />
      </Footer>
      {showCreateForm && (
        <CreateCustomer
          state={state}
          showEditId={showEditId}
          showEdit={showEdit}
          clearCustomer={clearCustomer}
          handleCreateSubmit={handleCreateSubmit}
          handleChange={handleChange}
          handleAutoComplete={handleAutoComplete}
          handleEditSubmit={handleEditSubmit}
        />
      )}
      {state.data.length > 0 && (
        <PaginationContainer>
          <StyledPagination
            count={state.total}
            // page={page}
            // size="small"
            onChange={(e, p) => setPage(p)}
          />
        </PaginationContainer>
      )}
    </Container>
  );
};

export default ListCustomer;

const Container = styled.div`
  padding: 5px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 15px;
`;
const NotFount = styled.div`
  text-align: center;
  margin-top: 100px;
  text-transform: capitalize;
  font-weight: bold;
`;
const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const StyledPagination = styled(Pagination)`
  width: 100%;
  .MuiPagination-ul {
    justify-content: center;
  }
`;
