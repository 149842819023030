import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components/macro";

const AmountTextLabel = (props) => {
  const [manual, setManual] = useState(false);
  return (
    <Container>
      <AmountTextGroup>
        <AmountText style={{ fontSize: props.fontSize }}>
          {props.label}
        </AmountText>
        {props.input ? (
          <CheckboxGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  size="small"
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  checked={manual}
                  onChange={() => setManual(!manual)}
                  label="hello"
                />
              }
              label="Manual"
            />
          </CheckboxGroup>
        ) : null}
        <p>-</p>
      </AmountTextGroup>
      <AmountTextValueGroup>
        {!props.input ? (
          <AmountTextValue style={{ fontSize: props.fontSize }}>
            {props.amount}
          </AmountTextValue>
        ) : manual ? (
          <StyledInput
            style={{
              width: props.state
                ? (props.state[props.name].length + 1) * 8 + "px"
                : "35px",
            }}
            defaultValue={props.amount}
            id={props.name}
            name={props.name}
            size="small"
            variant="outlined"
            // value={state.name}
            onChange={(e) => props.handleChange(e)}
          />
        ) : (
          <AmountTextValue style={{ fontSize: props.fontSize }}>
            {props.amount}
          </AmountTextValue>
        )}
      </AmountTextValueGroup>
    </Container>
  );
};

export default AmountTextLabel;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const AmountTextGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  p {
    font-weight: bold;
  }
`;
const AmountText = styled.p`
  font-weight: bold;
`;
const AmountTextValueGroup = styled.div``;
const AmountTextValue = styled.p`
  font-weight: bold;
`;
const StyledInput = styled(TextField)`
  min-width: 48px !important;
  max-width: 100px !important;
  &.top-input {
    &.MuiFormControl-fullWidth {
      width: max-content;
    }
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding: 3px;
  }
  input {
    color: #000;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    font-family: "poppinsregular";
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & textarea {
    min-height: 30px;
    max-height: 30px;
    color: #000;
  }
  & .MuiFormControl-root.MuiTextField-root {
    max-width: 100px;
    min-width: 50px !important;
  }
  & .MuiOutlinedInput-root {
    /* border-radius: 25px; */
    max-width: 180px;
    min-width: 50px;
    border: 2px solid #cacaca;
  }
  fieldset {
    border: 0;
  }
  &::-webkit-input-placeholder {
    color: #000;
  }
  & .MuiInputBase-input::-webkit-input-placeholder {
    color: #767676;
    opacity: 1;
    font-size: 12px;
  }
  & .MuiOutlinedInput-inputMarginDense {
    font-family: "poppinsregular";
    /* padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-right: 35px; */
    padding: 0;
  }
  &.MuiFormControl-fullWidth {
    width: 400px;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    padding: 0;
    /* margin-bottom: 5px; */
  }
  &.PrivateSwitchBase-root-1 {
    padding: 0;
    /* margin-bottom: 5px; */
  }
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #000;
  }
  &.MuiCheckbox-root {
    padding: 0;
  }
`;
const CheckboxGroup = styled.div`
  width: 30%;
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
  .MuiTypography-body1 {
    font-size: 12px;
    font-family: "poppinsregular";
  }
`;
