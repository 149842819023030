import React from "react";
import styled from "styled-components/macro";
import SaveButton from "./SaveButton";

const TopHeaderCreate = (props) => {
  return (
    <Header>
      <Heading>{props.title}</Heading>
      <HeaderRight>
        {props.import && (
          <ImportButton onClick={props.importClick}>
            Import From Quotation
          </ImportButton>
        )}
        <CreateButtonContainer>
          <SaveButton
            title={props.buttonTitle}
            type="submit"
            onClick={props.handleSubmit}
            saveButtonOption={props.saveButtonOption}
          />
        </CreateButtonContainer>
      </HeaderRight>
    </Header>
  );
};

export default TopHeaderCreate;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
`;
const Heading = styled.p`
  font-weight: bold;
  font-size: 24px;
`;
const HeaderRight = styled.div`
  display: flex;
`;
const CreateButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  padding: 8px 30px 8px 15px;
  color: #fff;
  border: 0;
  border-radius: 50px;
  background: #229d00;
  transition: all ease-in-out 0.3s;
  border: 1px solid;
  border-radius: 3px;
  position: relative;
  font-size: 14px;
  &:after {
    content: "";
    width: 8px;
    height: 8px;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    position: absolute;
    margin: auto 0;
    right: 13px;
    bottom: 13px;
    transform: rotate(315deg);
  }

  &:hover {
    opacity: 0.8;
  }
`;
const CreateButtonContainer = styled.div``;
const ImportButton = styled.button`
  background: #2652aa;
  cursor: pointer;
  border: 0;
  color: #fff;
  margin-right: 10px;
  padding: 0 15px;
  border-radius: 2px;
`;
